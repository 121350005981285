import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, IconButton, Tooltip, InputBase } from '@mui/material';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DescriptionIcon from '@mui/icons-material/Description';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { renameDocument, deleteDocument, setSelectedNode, setZeroLevelData } from '../Uploader/redux/actions/documentActions';
import AddFolderForm from './AddFolderForm';
import AddFileForm from './AddFileForm';

const Item = ({ item, level }) => {
  const [open, setOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(item.name);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [category, setCategory] = useState('');
  const dispatch = useDispatch();
  const selectedNode = useSelector((state) => state.documents.selectedNode);

  const toggleOpen = () => setOpen(prev => !prev);
  const handleMouseEnter = () => setShowActions(true);
  const handleMouseLeave = () => setShowActions(false);
  const handleDoubleClick = () => setEditing(true);

  const handleInputChange = (event) => setName(event.target.value);
  const handleInputBlur = () => {
    if (item.name !== name) {
      dispatch(renameDocument(item._id, name))
        .then(() => setEditing(false))
        .catch(() => console.log("ERROR: Rename failed"));
    } else {
      setEditing(false);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') handleInputBlur();
  };

  const handleClick = () => {
    if (item.category) {
      setCategory(item.category);
    }
    else if (item._id && item.parent === "") { setCategory(item._id); }
    dispatch(setSelectedNode(item._id));
    setSelectedNode(item._id);
    dispatch(setZeroLevelData(item._id));
    if (item.children) toggleOpen();
    console.log(selectedNode)
    // if(level === 0) {
    // } else {
    //     console.log('Test')
    // }
  };

  const handleEditIconClick = (event) => {
    event.stopPropagation();
    setEditing(true);
  };

  const handleDeleteIconClick = (event) => {
    event.stopPropagation();
    dispatch(deleteDocument(item.fileKey, item.dtype, item._id));
  };

  const handleOpenModal = (type) => {
    if (type === 'folder') {
      setIsFolderModalOpen(true);
    } else if (type === 'file') {
      setIsFileModalOpen(true);
    }
  };

  const handleCloseFolderModal = () => setIsFolderModalOpen(false);
  const handleCloseFileModal = () => setIsFileModalOpen(false);

  const handleFolderSubmit = (folderName) => {
    handleCloseFolderModal();
  };
  const handleFileSubmit = ({ file, fileName }) => {
    handleCloseFileModal();
  };

  const renderIcon = () => {
    if (item.dtype?.includes('image')) {
      return <ImageIcon sx={{ fontSize: '18px' }} />;
    } else if (item.dtype?.includes('document')) {
      return <DescriptionIcon sx={{ fontSize: '18px' }} />;
    } else if (item.dtype?.includes('pdf')) {
      return <PictureAsPdfIcon sx={{ fontSize: '18px' }} />;
    } else if (item.children) {
      return open ? <FolderOpenIcon sx={{ fontSize: '18px' }} /> : <FolderIcon sx={{ fontSize: '18px' }} />;
    } else {
      return <FolderIcon sx={{ fontSize: '18px' }} />;
    }
  };

  const renderArrowIcon = () => {
    if (item.children && item.children.length > 0) {
      return open ? <ArrowDropDownIcon /> : <ArrowRight />;
    } else {
      return <span style={{ color: '#9e9e9e', opacity: 0 }}><ArrowDropDownIcon /></span>;
    }
  };

  return (
    <>
      <AddFolderForm
        open={isFolderModalOpen}
        onClose={handleCloseFolderModal}
        onFolderAdded={handleFolderSubmit}
        category={category}
      />
      <AddFileForm
        open={isFileModalOpen}
        onClose={handleCloseFileModal}
        onSubmit={handleFileSubmit}
        category={category}
      />
      <Box onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ cursor: 'pointer' }}>
        <Box
          display="flex"
          alignItems="center"
          mt={1}
          onClick={handleClick}
          sx={{ cursor: 'pointer' }}
        >
          <IconButton size="small" sx={{ color: '#9e9e9e' }}>
            {renderArrowIcon()}
          </IconButton>
          <IconButton size="small" sx={{ color: item.dtype ? '#1e88e5' : '#424242' }}>
            {renderIcon()}
          </IconButton>
          {editing ? (
            <InputBase
              value={name}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyPress={handleKeyPress}
              autoFocus
              sx={{ ml: 1, fontWeight: 500, color: '#333', width: 'auto' }}
            />
          ) : (
            <Typography
              variant="body1"
              sx={{ ml: 1, fontWeight: 500, color: item._id === selectedNode ? '#1e88e5' : '#424242', cursor: 'pointer', userSelect: item.parent !== "" ? 'auto' : 'none' }}
              onDoubleClick={item.parent !== "" && handleDoubleClick}
              noWrap
            >
              {item.name === '' ? item.fileKey : item.name}
            </Typography>
          )}

          {showActions && (
            <Box ml="auto" sx={{ whiteSpace: 'nowrap' }}>
              <Tooltip title="Create Folder">
                <IconButton size="small" onClick={() => handleOpenModal('folder')}>
                  <FolderIcon sx={{ color: '#1e88e5', fontSize: '14px' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Add File">
                <IconButton size="small" onClick={() => handleOpenModal('file')}>
                  <InsertDriveFileIcon sx={{ color: '#1e88e5', fontSize: '14px' }} />
                </IconButton>
              </Tooltip>
              {item.parent !== "" && <><Tooltip title="Edit">
                <IconButton size="small" onClick={handleEditIconClick}>
                  <EditIcon sx={{ color: '#1e88e5', fontSize: '14px' }} />
                </IconButton>
              </Tooltip>
                <Tooltip title="Delete">
                  <IconButton size="small" onClick={handleDeleteIconClick}>
                    <DeleteIcon sx={{ color: '#f44336', fontSize: '14px' }} />
                  </IconButton>
                </Tooltip></>}
            </Box>
          )}
        </Box>
        {item.children && open && (
          <Box pl={1.1}>
            {item.children.map(child => (
              <Box key={child._id} mt={1}>
                <Item item={child} level={level + 1} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Item;
