import React from 'react';
import { Box, Typography } from '@mui/material';
const Sidebar = ({ children }) => {
  return (
    <Box
      sx={{
        width: '100%',
        boxShadow: 2,
        borderRadius: 2,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5',
        marginBottom:'10px'
      }}
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '16px',
          borderBottom: '1px solid #d5d5d5',
          p: 1,
          px: 2,
          color: '#333',
        }}
      >
        Document list
      </Typography>
      <Box
        sx={{
          flex: 1,
          overflowY: 'auto',
        }}
      >
        {children}
      </Box>
    </Box >
  );
};

export default Sidebar;
