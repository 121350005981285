import { Layout, Spinner } from 'components'
import React, { useEffect, useState } from 'react'
import API from 'services/AxiosConfig'

import LineChart from 'components/Chart/LineChart'
import { formatQueryObj } from '../common'




function ProspectsWinPercentage(props: any) {
    const { filter} = props
    const [chartData, setChartData] = useState<any>(null)
    const [max, setMax] = useState<any>(null)
    const [loading,setLoading] = useState(false)
    
    useEffect(() => {
        setLoading(true)
        const queryObj = formatQueryObj(filter)
        API.post('prospects/reporting/won-lost', queryObj)
            .then(data => {
                formatChartData(data.data);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false)
            });
    }, [filter]);

    const formatChartData = (rawData: any) => {
        // Extract headers and rows from the raw data
        const { headers, rows } = rawData.data;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const filteredHeaders = headers.filter((header: any) => {
            const headerDate = new Date(header);
            return headerDate <= today;
        });

        // Initialize arrays to hold the counts of 'won' and 'lost' prospects for each period
        let wonCounts: number[] = [];
        let lostCounts: number[] = [];
        let percentages: number[] = []

        // Loop through each row to populate the 'wonCounts' and 'lostCounts' arrays
        rows.forEach((row: any) => {
            const { label, values } = row;

            if (label === 'Won') {
                wonCounts = values.map((v: any) => v.countForStage);
            }

            if (label === 'Lost') {
                lostCounts = values.map((v: any) => v.countForStage);
            } 

         if(wonCounts.length && lostCounts.length){
            wonCounts.forEach((item,i)=> {
                let percentage = (item / (item + lostCounts[i]) * 100) || 0
                percentages.push(Math.trunc(percentage))
            })
         }
            
            
        });
        const lastIndex = filteredHeaders.length;
        const filteredPercantagesCounts = percentages.slice(0, lastIndex);

        // Create the chart data
        const chartData = {
            labels: headers,  // remove the first and last element, assuming they are not relevant for the chart
            datasets: [
                            {
                                label: 'Win Percentage',
                                data: filteredPercantagesCounts,
                                borderColor: '#3366CC', // use borderColor for line charts
                                fill: false // do not fill under the line
                            }
                        ]
        };

        // If you want to find the maximum value among both won and lost counts
        const allCounts = [...wonCounts, ...lostCounts];
        const max = Math.max(...allCounts);

        // Use the setMax and setChartData methods if you have them to update the state
        setMax(max);
        setChartData(chartData);

        // return { chartData, max };
    };

    const chartOptions = {
        maintainAspectRatio: false,
        layout: {
            padding: 25
        },
        config:{
           borderColor:'blue' 
        },
        scales: {
            x: {
                grid:{
                    display:false
                },
                
            },
            y: {
                beginAtZero: true,
                ticks:{
                    maxTicksLimit:10,
                    callback: function(value:any) {
                        return value + "%"
                    }
                 },
                 scaleLabel: {
                    display: true,
                    labelString: "Percentage"
                },
                 suggestedMax: max +2,
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels: {
                display: true,
                color: 'black',
                align: 'top',
                offset:8,
                font: {
                    size: 12 // or a smaller size depending on your need
                  },
                
                formatter: function(value:any, context:any) {
                    let idx = context.dataIndex
                    let dataSet = context.dataset.data
                    let displayAngleCondition = filter.timeFrame.id === 'last12Months' ? 30 : 50
                    let prevNum = dataSet[idx -1]
                    let currentNum = dataSet[idx]
                    let futureNum = dataSet[idx + 1]
                    
                   
                    if(idx === 0) return null
                    if((prevNum && currentNum)&& prevNum-currentNum > displayAngleCondition ){
                        return null
                    }
                    if((futureNum && currentNum)&& futureNum-currentNum > displayAngleCondition ){
                        return null
                    }
                    
                  if(value === 0)return null
                    return value.toFixed(0) + "%"; // format value to two decimal places and append '%'
                   
                }
            }
        },
    }
    return (
        
        <div style={{ height: '350px', width: '700px', }}>
                <h3 >Prospects Win Percentage</h3>
                {chartData && !loading ? <LineChart chartData={chartData} option ={chartOptions}  /> : <div><Spinner/></div>}
            </div>
    )
}

export default ProspectsWinPercentage


