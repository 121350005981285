export const fileKey = (file, prefix = "") => {
    const [fileName, extension] = file.name.split(".");

  // to generate unique key everytime
  let key = prefix + `/${fileName}-${new Date().valueOf()}.${extension}`;

  let content_type = file.type;

  return { key, content_type };
};
