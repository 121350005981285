import React, { useEffect, useState } from 'react';
import ReportingTable from '../ReportingTable';
import API from 'services/AxiosConfig';
import { formatQueryObj } from '../common';

const ApplicantsBySourceTable = ({ filter }: any) => {
    const [data, setData] = useState({ headers: [], rows: [] })
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        const queryObj = formatQueryObj(filter)
        API.post('/applicants/reporting/monthly-prospects-by-source', queryObj)
            .then(({ data }: any) => {
                setData(data?.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [filter]);




    return <ReportingTable loading={loading} setLoading={setLoading} headers={data?.headers || []} rows={data?.rows || []} sumRowLabel="Total New Applicants" sheetName='New Applicants by Source' />

};

export default ApplicantsBySourceTable;


