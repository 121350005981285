import { Box, Stack, TextField, Autocomplete as MuiAutocomplete} from '@mui/material'
import { useEffect, useState } from 'react'
import { useFormik } from 'formik';
import { ActionButtons, Modal, Spinner } from 'components';
import Autocomplete from 'components/Form/Autocomplete';
import API from 'services/AxiosConfig';
import { toast } from 'react-toastify';
import DeleteInput from './DeleteInput';
import { useNavigate } from "react-router-dom";
import { AnyARecord } from 'dns';


function MovePartnerModal({ closeHandler, id, name, dataTarget }: any) {
    const [referralPartners, setReferralPartners] = useState([])
    const [companies, setCompanies] = useState([])
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();

    const initialValues: any = {
        partner: null,
        company: null
    }
    const fetchReferralPartner = async () => {
        setLoading(true)
        const partners = await API.get('referral-partners')
        setReferralPartners(partners.data.data)
        setLoading(false)
    }
    const fetchCompanies = async () => {
        setLoading(true)
        const partners = await API.get('referral-partners/companies/listing')
        console.log()
        setCompanies(partners.data.data)
        setLoading(false)
    }

    const handleDeleteClick = () => {
        let condition = dataTarget === 'Referral Partner' ? formik.values.partner : formik.values.company
        if (condition) {
            setConfirmDeleteModal(true)
        } else {
            toast.error(`Must select ${dataTarget} to recieve old data`)
        }
    }
    const handleDeleteSubmit = async () => {
        try {
            let isReferralPartner = dataTarget === 'Referral Partner'
            let urlId = isReferralPartner ? formik?.values?.partner?._id:formik?.values?.company?._id
            let url = isReferralPartner ? 
                `referral-partners/archive-and-move?to=${formik?.values?.partner?._id}&from=${id}` :
                `referral-partners/company/archive-and-move?to=${formik?.values?.company?._id}&from=${id}`

            const created = await API.delete(url)
            toast.success('Sucessfully archived/moved referral partner.')
            setConfirmDeleteModal(false)
            closeHandler(urlId)
            navigate('/marketing/referral-partners')
        } catch (err) {
            console.error(err)
            toast.success('Failed to archive/move referral partner.')

        }
    }

    useEffect(() => {
        fetchReferralPartner()
        fetchCompanies()
    }, [])

    const formik = useFormik({
        initialValues,
        onSubmit: (values) => {

        }
    },
    );

    return (
        <form onSubmit={formik.handleSubmit}>
            <Modal open={confirmDeleteModal} styles={{ width: '20%', height: '20%' }} >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2 className="fs-30 pt"> Confirm Delete</h2>
                </div>
                <DeleteInput
                    handleDeleteSubmit={handleDeleteSubmit}
                    targetWord='DELETE'
                />
            </Modal>
            <Stack>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h2 className="fs-30 pt"> Delete: {name}</h2>
                </div>
                {loading ? <Spinner /> : <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '100%',
                        columnGap: '15px',
                    }}
                >
                    <div style={{ width: '100%' }}>
                        {dataTarget === 'Referral Partner' ?
                            <Autocomplete
                                value={formik.values.partner}
                                options={
                                    [
                                        { id: '', firstName: '', lastName: '' },
                                        ...referralPartners
                                    ]
                                }
                                onChange={(event: any, newValue: any | null) => {
                                    if (null || !newValue) {
                                        formik.setFieldValue('partner', { id: '', firstName: '', lastName: '' });

                                    }
                                    else {
                                        formik.setFieldValue('partner', { _id: newValue._id, firstName: newValue.firstName, lastName: newValue.lastName });
                                    }
                                }}
                                label='Move To Referral Partner'
                            /> :
                            <MuiAutocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={[
                                    { id: 'Add Company', label: 'ADD COMPANY' },
                                    ...companies.map((company: any) => {
                                        return { label: company.companyName, id: company._id }
                                    })]}
                                sx={{ width: '100%' }}
                                value={formik.values.company}
                                renderInput={(params:any) => <TextField {...params} label="Company*"  />}
                                isOptionEqualToValue={(option: { label: any; }, value: any) => {
                                    return option.label === value
                                }}
                                renderOption={(props:any, option:any) => {
                                    return <li {...props} key={option?.id}>{option?.label}</li>
                                }}
                                onChange={(event: any, newValue: any) => {
                                   if(!newValue || newValue.label === ''){
                                    formik.setFieldValue('company',{_id:'',label:''})
                                   }else{
                                    formik.setFieldValue('company',{_id:newValue.id,label:newValue.label})
                                   }
                                }}
                            />}
                    </div>
                </Box>}
            </Stack>
            <ActionButtons renderDelete={true} deleteHandler={() => { handleDeleteClick() }} renderEmail={false} closeHandler={closeHandler} renderSubmit={false} />
        </form>
    )
}

export default MovePartnerModal