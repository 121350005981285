import React, { useState, useEffect } from 'react';
import API from 'services/AxiosConfig';
import {
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Button
} from '@mui/material';
import * as XLSX from 'xlsx';
import { NoData, Spinner } from 'components';
import { formatQueryObj } from '../common';

const data = {
    headers: ['Jan-23', 'Feb-23', 'Mar-23', 'Apr-23', 'May-23', 'Jun-23'],
    section1: [
        {
            label: 'Beginning Prospects',
            values: []
        },
        {
            label: 'New Prospects',
            values: []
        }
    ],
    section2: [
        {
            label: 'Prospects Won',
            values: []
        },
        {
            label: 'Prospects Lost',
            values: []
        }
    ]
};
interface Props { filter: any }
interface RowData {
    label: string;
    values: Array<number | string>;
}

function ReferralPartnersProspectTable(props: Props) {
    const { filter } = props
    const [loading, setLoading] = useState(false)
    const [tableData, setTableData] = useState<any>(data)
    const headers = ['Prospect Activity', ...tableData?.headers || ''];
    function calculateSum(section: any) {
        return section.reduce((totals: any, row: any) => {
            row.values.forEach((value: any, i: any) => {
                totals[i] = (totals[i] || 0) + value;
            });
            return totals;
        }, []);
    }

    useEffect(() => {
        setLoading(true)
        const queryObj = formatQueryObj(filter)
        console.log('queryObj2',queryObj)
        API.post('/prospects/monthly-beginning-prospects', queryObj)
            .then(({ data }: any) => {
                setTableData(data?.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [filter]);


    const setColumnWidths = (ws: any, numOfCols: number, defaultWidth: number) => {
        const colWidths = Array.from({ length: numOfCols }, () => ({ wpx: defaultWidth }));
        ws['!cols'] = colWidths;
    };
    function renderFirstColumn(row: any, i: any, isBold = false) {
        return (
            <TableRow key={i} /*style = {{borderTop:'2px solid black'}}*/>
                <TableCell component="th" scope="row" style={{ fontWeight: isBold ? 'bold' : 'normal', minWidth: '200px' }}>{row.label}</TableCell>
            </TableRow>
        );
    }
    function renderFirstColumnTopBorder(row: any, i: any, isBold = false) {
        return (
            <TableRow key={i} style = {{borderTop:'2px solid black'}}>
                <TableCell component="th" scope="row" style={{ fontWeight: isBold ? 'bold' : 'normal', minWidth: '200px' }}>{row.label}</TableCell>
            </TableRow>
        );
    }
    const exportToExcel = () => {
        setLoading(true)
        const copy:any = tableData.section1.slice()
        const copy2:any = tableData.section2.slice()
        const totalRow = {
            label: 'Total Prospects',
            values: tableData?.headers?.map((_: any, i: any) => {
                const beginningClients = copy?.find((row: any) => row.label === 'Beginning Prospects')?.values[i] || 0;
                const newClients = copy?.find((row: any) => row.label === 'New Prospects')?.values[i] || 0;
                // const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                return (beginningClients + newClients) 
            })
        };
        const totalRow2 = {
            label: 'Ending Prospects',
            values: tableData?.headers?.map((_: any, i: any) => {
                const beginningClients = copy?.find((row: any) => row.label === 'Beginning Prospects')?.values[i] || 0;
                const newClients = copy?.find((row: any) => row.label === 'New Prospects')?.values[i] || 0;
                const applicantsHired = copy2?.find((row: any) => row.label === 'Prospects Won')?.values[i] || 0;
                const applicantsLost = copy2?.find((row: any) => row.label === 'Prospects Lost')?.values[i] || 0;
                // const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                return (beginningClients + newClients) - (applicantsHired + applicantsLost)
            })
        };
        const totalRow3 = {
            label:'Win / Loss Ratio',
            values: tableData?.headers?.map((_: any, i: any) => {
                const applicantsHired = copy2?.find((row: any) => row.label === 'Prospects Won')?.values[i] || 0;
                const applicantsLost = copy2?.find((row: any) => row.label === 'Prospects Lost')?.values[i] || 0;
                // const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                let ratio = applicantsHired / applicantsLost
                ratio = !isNaN(ratio) ? ratio : 0
                return  Number(ratio.toFixed(2))
            })
        }
        const totalRow4 = {
            label:'Win Percentage',
            values: tableData?.headers?.map((_: any, i: any) => {
                const applicantsHired = copy2?.find((row: any) => row.label === 'Prospects Won')?.values[i] || 0;
                const applicantsLost = copy2?.find((row: any) => row.label === 'Prospects Lost')?.values[i] || 0;
                // const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                let ratio = applicantsHired  / (applicantsLost + applicantsHired)
                ratio = !isNaN(ratio) ? ratio : 0
                ratio *= 100
                return  ratio.toFixed(2) +'%'
            })
        }
        // copy.push(totalRow)
        copy2.push(totalRow2,totalRow3,totalRow4)
        const ws = XLSX.utils.json_to_sheet([...copy.map((row:any) => {
          let obj: { [key: string]: number | string } = {};
          // Use the first value of headers for the label
          let total = 0
          obj[headers[0]] = row.label;
          // Map values to the other headers
          row.values.forEach((value:any, index:number) => {
        
            obj[headers[index + 1]] = value === 0 ? '-': value; // '+1' to skip the first header
            total += value
            
          });
         
          return obj;
        }),
        ...copy2.map((row:any) => {
            let obj: { [key: string]: number | string } = {};
            // Use the first value of headers for the label
            let total = 0
            obj[headers[0]] = row.label;
            // Map values to the other headers
            row.values.forEach((value:any, index:number) => {
              obj[headers[index + 1]] = value === 0 ? '-': value; // '+1' to skip the first header
              if(value[value.length-1] === '%'){
                total += Number(value.slice(0,-1))
              }else{
                  total += value
              }
            });
            // obj.Total = row.label === 'Win Percentage' ? total + '%':total
            return obj;
          })
    ]);
        setColumnWidths(ws, headers.length, 100);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `Prospect_Activity.xlsx` || "data.xlsx");
        setLoading(false)
        
    }
    function calculateEndingProspects(section1: any, section2: any) {
        const totalProspects = section1.reduce((total: any, row: any) => total + (row.values.reduce((a: any, b: any) => a + b, 0)), 0);
        const prospectsWon = section2.find((row: any) => row.label === 'Prospects Won');
        const prospectsLost = section2.find((row: any) => row.label === 'Prospects Lost');

        if (!prospectsWon || !prospectsLost) return null;

        const prospectsWonTotal = prospectsWon.values.reduce((a: any, b: any) => a + b, 0);
        const prospectsLostTotal = prospectsLost.values.reduce((a: any, b: any) => a + b, 0);

        return totalProspects - (prospectsWonTotal + prospectsLostTotal);
    }

    function calculateWinLossRatio(section: any) {
        const won = section.find((row: any) => row.label === 'Prospects Won');
        const lost = section.find((row: any) => row.label === 'Prospects Lost')
        return won.values.map((value: any, i: any) => {
            const wonValue = parseFloat(value);
            const lostValue = parseFloat(lost.values[i]) || 1;
            return (Number(wonValue) / Number(lostValue)).toFixed(2);
        });
    }

    function calculateWinPercentage(section: any) {
        const won = section.find((row: any) => row.label === 'Prospects Won');
        const lost = section.find((row: any) => row.label === 'Prospects Lost');
        return won.values.map((value: any, i: any) => ((value / ((value + lost.values[i]) || 1)) * 100).toFixed(2) + '%');
    }
    function calculateRow(section: any, section2: any) {

        return tableData?.headers?.map((_: any, i: any) =>
            section.reduce((total: any, row: any) => total + (row.values[i] || 0), 0))
    }
    function renderEndingProspectsRow(label: any, section: any, section2: any) {
        let totalProspects = calculateRow(section2, section)
        let totalRow = {
            label: label,
            values: tableData?.headers?.map((_: any, i: any) =>
                section.reduce((total: any, row: any) => total + (row.values[i] || 0), 0))
        };

        return (
            <TableRow key={label} style = {{borderTop:'2px solid black'}}>
                {totalRow.values.map((value: any, j: any) =>
                    <TableCell key={j} align="right" style={{ fontWeight: 'bold' }}>{totalProspects[j] - value}</TableCell>
                )}
            </TableRow>
        );
    }

    function renderSumRow(label: any, section: any) {
        const totalRow = {
            label: label,
            values: tableData?.headers?.map((_: any, i: any) =>
                section.reduce((total: any, row: any) => total + (row.values[i] || 0), 0))
        };
        return (
            <TableRow key={label} style = {{borderTop:'2px solid black'}}>
                {totalRow.values.map((value: any, j: any) =>
                    <TableCell key={j} align="right" style={{ fontWeight: 'bold' }}>{value}</TableCell>
                )}
               
            </TableRow>
        );
    }

    function renderWinLossRatioRow(section: any) {
        const winRow = section.find((row: any) => row.label === 'Prospects Won');
        const lossRow = section.find((row: any) => row.label === 'Prospects Lost');

        if (!winRow || !lossRow) return null;

        const ratioRow = {
            label: 'Win / Loss Ratio',
            values: tableData?.headers?.map((_: any, i: any) => {
                let numerator = parseFloat(winRow?.values[i])
                let denominator = parseFloat(lossRow.values[i])
                let ratio = numerator / denominator
                let num = lossRow.values[i] || 0 !== 0 ? (ratio.toFixed(2) || 0) : 0
                return num
            })

        };

        return renderRow(ratioRow, ratioRow.label, true);
    }

    function renderWinPercentageRow(section: any) {
        const winRow = section.find((row: any) => row.label === 'Prospects Won');
        const lossRow = section.find((row: any) => row.label === 'Prospects Lost');

        if (!winRow || !lossRow) return null;

        const percentageRow = {
            label: 'Win Percentage',
            values: tableData?.headers?.map((_: any, i: any,arr:any) =>{
               return (winRow.values[i] || 0) + (lossRow.values[i] || 0) !== 0 ? (winRow.values[i] || 0) / ((winRow.values[i] || 0) + (lossRow.values[i] || 0)) * 100 : 0
            }
                )
                
        };

        return renderRow(percentageRow, percentageRow.label, true);
    }

    function prepareDataForExcel(rowData: RowData[], headers: string[]): { [key: string]: number | string }[] {
        return rowData.map(row => {
            let ytd = 0;
            const obj: { [key: string]: number | string } = { "Prospect Activity": row.label };
            row.values.forEach((value, index) => {
                if (typeof value === "number") {
                    ytd += value;
                }
                obj[headers[index]] = value;
            });
            // obj['Total'] = ytd
            return obj;
        });
    }
    function renderRow(row: any, i: any, isBold = false,renderLast=false) {
        let vals = renderLast ? row.values :row.values.slice(0,-1)
        const includedRows = ['Ending Prospects','Prospects Lost','Prospects Won','New Prospects']
        return (
            <TableRow key={i}>
                {row.values.map((value: any, j: any) =>
                    <TableCell key={j} align="right" style={{ fontWeight: isBold ? 'bold' : 'normal' }}>{row.label === 'Win Percentage' ? value.toFixed(2) + '%' : value === 0 ? '-': value}</TableCell>
                )}
               
            </TableRow>
        );
    }

    return (
        <div style ={{marginTop:'10%', width: '100%', maxWidth: '1700px',}}>
      <Button variant="contained" color="primary" style={{ margin: 0, marginBottom: '5px',float:'right' }} onClick={exportToExcel} >
                Download Data
            </Button>
            <div style={{ display: 'flex', flexDirection: 'row',width: '100%', maxWidth: '1700px', borderRadius:'4px 0 0 4px' }}>
                <TableContainer component={Paper} style={{ width: 'auto', overflow:'hidden'}}>
                    <MuiTable>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ backgroundColor: '#2f374e', color: 'white',minWidth:'200px' }}>Prospect Activity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center', padding: '20px' }}>
                                        <Spinner />
                                    </TableCell>
                                </TableRow>
                            ) : tableData?.section1.length === 0 ? (
                                <TableRow>
                                    <TableCell style={{ textAlign: 'center', padding: '20px' }}>
                                        <NoData />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {tableData.section1.map((row: any, i: any) => renderFirstColumn(row, i))}
                                    <TableRow sx={{ borderTop: '2px solid #2f374e' }} />
                                    {renderFirstColumnTopBorder({ label: 'Total Prospects' }, 'total', true)}

                                    {tableData.section2.map((row: any, i: any) => renderFirstColumn(row, i))}
                                    <TableRow sx={{ borderTop: '2px solid #2f374e' }} />
                                    {renderFirstColumnTopBorder({ label: 'Ending Prospects' }, 'ending', true)}
                                    {renderFirstColumn({ label: 'Win / Loss Ratio' }, 'winLoss', true)}
                                    {renderFirstColumn({ label: 'Win Percentage' }, 'winPercentage', true)}
                                </>
                            )}
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <TableContainer component={Paper} style = {{borderRadius:'0 4px 4px 0'}}>
                    <MuiTable>
                        <TableHead>
                            <TableRow>
                                {headers.slice(1).map((header, i) =>
                                    <TableCell style={{ backgroundColor: '#2f374e', color: 'white', minWidth: '75px' }} key={i} align={'right'} >{header}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                // Render only the Spinner when loading
                                <TableRow>
                                    <TableCell
                                        colSpan={Math.min(headers.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <Spinner />
                                    </TableCell>
                                </TableRow>
                            ) : tableData?.section?.length === 0 ? (
                                // Render 'No Data' component or message if there are no rows and not loading
                                <TableRow style={{ width: '100%' }}>
                                    <TableCell
                                        colSpan={Math.min(headers.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <NoData />
                                    </TableCell>
                                </TableRow>
                            ) : (<>
                                {tableData.section1.map((row: any, i: any) => renderRow(row, i))}
                                <TableRow sx={{ borderTop: '2px solid #2f374e' }} />
                                {renderSumRow('Total Prospects', tableData.section1)}

                                {tableData?.section2?.map((row: any, i: any) => renderRow(row, i))}
                                <TableRow sx={{ borderTop: '2px solid #2f374e' }} />
                                {renderEndingProspectsRow('Ending Prospects', tableData.section2, tableData.section1)}
                                {renderWinLossRatioRow(tableData.section2)}
                                {renderWinPercentageRow(tableData.section2)}
                            </>
                            )}

                        </TableBody>
                    </MuiTable>
                </TableContainer>
            </div>
        </div>
    );
}

export default ReferralPartnersProspectTable