import { Spinner } from 'components'
import BarChart from 'components/Chart/BarChart'
import React, { useEffect, useState } from 'react'

function ActivityReferralChart(props: any) {
    const { activityReferralData } = props
    const { filter, loading } = props
    const [activityChartData, setActivityChartData] = useState<any>(null)
    const [referralchartData, setReferralchartData] = useState<any>(null)
    //const [loading,setLoading] = useState(false)

    useEffect(() => {
        const ActivityChartData = {
            labels: activityReferralData?.headers,  // remove the first and last element, assuming they are not relevant for the chart
            datasets: [

                {
                    label: '',
                    data: activityReferralData?.activity,
                    backgroundColor: '#7cb5ec',
                }
            ]
        };
        setActivityChartData(ActivityChartData);
        const ReferralchartData = {
            labels: activityReferralData?.headers,  // remove the first and last element, assuming they are not relevant for the chart
            datasets: [

                {
                    label: '',
                    data: activityReferralData?.refferal,
                    backgroundColor: '#7cb5ec',
                }
            ]
        };
        setReferralchartData(ReferralchartData)
    }, [filter,loading]);


    const chartOptions = {
        maintainAspectRatio: false,
        layout: {
            padding: 25
        },
        scales: {

            x: {
                grid: {
                    display: false
                },
            },
            y: {
                beginAtZero: true,
                ticks: {
                    maxTicksLimit: 10
                },


            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels: {
                color: 'white',
                display: function (context: any) {
                    return context.dataset.data[context.dataIndex] > 1; // or >= 1 or ...
                }
            }

        },
    }

    // referralData

    return (
        <>
            <div style={{ height: '300px', width: '500px', }}>
                <h3 style={{ textAlign: "center" }} >Marketing Activities</h3>
                {activityChartData && !loading ? <BarChart chartData={activityChartData} options={chartOptions} renderLabels /> : <div><Spinner /></div>}

            </div>

            <div style={{ height: '300px', width: '500px', }}>
                <h3 style={{ textAlign: "center" }} >Marketing Referrals</h3>
                {referralchartData && !loading ? <BarChart chartData={referralchartData} options={chartOptions} renderLabels /> : <div><Spinner /></div>}

            </div>
        </>
    )
}

export default ActivityReferralChart


