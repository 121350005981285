import React, { useEffect, useState } from 'react'
import API from 'services/AxiosConfig'
import BarChart from 'components/Chart/BarChart'
import { Spinner } from 'components';
import { formatQueryObj } from '../common';
import LineChart from 'components/Chart/LineChart';

// Define type for the incoming data
type InputData = {
    count: number,
    year: number,
    month: number,
    stage: string
}[];



function ApplicantsNewAndHired({filter}:any) {
    const [chartData, setChartData] = useState<any>(null)
    const [max , setMax ] = useState(10)
    const [loading,setLoading] = useState(false)

    const formatChartData = (rawData: any) => {
        const { headers, rows } = rawData.data;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const filteredHeaders = headers.filter((header: any) => {
            const headerDate = new Date(header);
            return headerDate <= today;
        });
        let wonCounts: number[] = [];
        let lostCounts: number[] = [];


        rows.forEach((row: any) => {
            const { label, values } = row;

            if (label === 'Hired') {
                wonCounts = values.map((v: any) => v.countForStage);
            }

        });

        const lastIndex = filteredHeaders.length;

        // Filter data arrays to match the new headers array
        const filteredWonCounts = wonCounts.slice(0, lastIndex);
        const filteredLostCounts = lostCounts.slice(0, lastIndex);


        const chartData = {
            labels: headers,
            datasets: [
                {
                    type:'line',
                    label: 'Hired',
                    data: filteredWonCounts,
                    backgroundColor: '#7cb5ec',
                   
                },
                
            ]
        };


        const allCounts = [...wonCounts, ...lostCounts];
        const max = Math.max(...allCounts);


        setMax(max);
        setChartData(chartData);

    };

    useEffect(() => {
       setLoading(true)
       const queryObj = formatQueryObj(filter);
       console.log('new v hired',queryObj)

        API.post('applicants/reporting/new-and-hired', queryObj)
            .then(data => {

                formatChartData(data.data);
                setLoading(false)
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false)
            });
    }, [filter]);


    const chartOptions = {
        maintainAspectRatio: false,
        layout: {
            padding: 25
        },
        scales: {
            x: {
                grid: {
                    display: false
                },

                stacked: true,
            },
            y: {
                beginAtZero: true,
                ticks: {
                    maxTicksLimit: 10
                },
                suggestedMax: 18,
            }
        },
        plugins: {
            legend: {
                position: 'bottom',
            },
            datalabels: {
                align: 'top',
                labels: {
            
                  value: {
                    color: 'black'
                  }
                },
                formatter: function(value:any, context:any) {
                  let idx = context.dataIndex
                  let dataSet = context.dataset.data
                  let displayAngleCondition = filter.timeFrame.id === 'last12Months' ? 30 : 50
                  let prevNum = dataSet[idx -1]
                  let currentNum = dataSet[idx]
                  let futureNum = dataSet[idx + 1]
                  
                 
                  if(idx === 0) return null
                  if((prevNum && currentNum)&& prevNum-currentNum > displayAngleCondition ){
                      return null
                  }
                  if((futureNum && currentNum)&& futureNum-currentNum > displayAngleCondition ){
                      return null
                  }
                  
                if(value === 0)return null
                  return value;
              }
              }
            }
        
        }

    return (
        <div style={{ height: '350px', width: '700px' }}>
            <h3  >Applicants Hired</h3>
            {chartData && !loading ? <LineChart chartData={chartData} option={chartOptions}  /> : <div><Spinner/></div>}
        </div>
    )
}

export default ApplicantsNewAndHired
