import React from 'react'
import ClientBySourceTable from './ClientBySourceTable'

import ClientByLostReason from './ClientByLostReason'
import CpActivityTable from './CpActivityTable'

interface Props { filter: any }

function CpReportingTables(props: Props) {
  const { filter } = props

  return (
    <div>
      <CpActivityTable filter={filter} />
      <ClientByLostReason filter={filter} url='/care-partner/monthly-lost-carePartners' title = {'Total Lost Care Partners'} sheetName = 'Lost Care Partners by Reason'/>
      <ClientByLostReason filter={filter} url='/care-partner/monthly-lost-carePartners-tenure' title = {'Total Lost Care Partners'} sheetName='Lost Care Partners Tenure (days)'/>
    </div>
  )
}

export default CpReportingTables
