import { Modal as MuiModal, Box, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { ComponentDivProps } from 'typings';
import React, { CSSProperties, useEffect, useState } from 'react'
import { useModal } from './ModalContext';
const modalBackdropStyle: CSSProperties = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const modalContentStyle: CSSProperties = {
  width: '50%',
  backgroundColor: 'background.paper',
  padding: 4,
  maxHeight: 'calc(100vh - 96px)',
  overflowY: 'auto',
};

export function Modal({ open, closeHandler, children, title, styles, radioGroup = false, radioGroupLabel1, radioGroupLabel2, currentRow }: any) {
  const [completed, setCompleted] = useState('Referral Partner');
  const [activeModal, setActiveModal] = useState(null)
  const { openModal, closeModal } = useModal()

  useEffect(() => {
    // Set the initial value of the "completed" state based on the "currentRow" prop
    if (radioGroupLabel1 === 'Referral Partner') {
      const val = currentRow?.state?.currentRow?.prospect ? 'Prospect' : 'Referral Partner'
      setCompleted(val)
    } else {
      currentRow && setCompleted(currentRow && currentRow?.state?.currentRow?.completed || currentRow?.state?.value?.completed === true || (currentRow?.status?.value === 'Closed' && title !== 'Edit Marketing Task') ? 'Completed' : 'Incomplete');
    }
  }, [currentRow]);

  useEffect(() => {
    if (open) {
      openModal(title)
    } else {
      closeModal(title)
    }
    return () => closeModal(title)
  }, [open])

  return (
    <MuiModal
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={modalBackdropStyle}>
        <Box sx={styles ? { ...modalContentStyle, ...styles } : modalContentStyle}>

          {title && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}><h2 className="fs-30 text-primary">{title}</h2>  {radioGroup && (
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                row
                value={completed}
                onChange={(e: any) => setCompleted(e.target.value)}
              >
                <FormControlLabel value={radioGroupLabel1} control={<Radio />} label={radioGroupLabel1} />
                <FormControlLabel value={radioGroupLabel2} control={<Radio />} label={radioGroupLabel2} />
              </RadioGroup>
            </FormControl>
          )}</div>}
          {radioGroup ? React.cloneElement(children, { completed }) : children}
        </Box>
      </div>
    </MuiModal>
  );
}