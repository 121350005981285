import React , {useEffect, useState}from 'react';
import ReportingTable from '../ReportingTable';
import API from 'services/AxiosConfig';
import { formatQueryObj } from '../common';

const ProspectsBySourceTable = ({filter}:any) => {
const [data,setData] = useState({headers:[], rows:[]})
const [loading,setLoading] = useState(false)

useEffect(() => {
    setLoading(true)
  const queryObj = formatQueryObj(filter)
  console.log('queryObj',queryObj)
  API.post('/prospects/monthly-prospects-by-source', queryObj)
      .then(({data}:any) => {
          setData(data?.data)
          setLoading(false)
      })
      .catch(err =>{
          console.log(err)
          setLoading(false)
      })
}, [filter]);
  
  return <ReportingTable loading = {loading} setLoading = {setLoading} headers={data?.headers || []} rows={data?.rows|| []} sumRowLabel="Total New Prospects" sheetName='Prospects by Source'/>

};

export default ProspectsBySourceTable;


