import React , {useEffect, useState}from 'react';
import ReportingTable from '../ReportingTable';
import API from 'services/AxiosConfig';

const ClientByLostReason = ({filter, url,title,sheetName}:any) => {

const [data,setData] = useState({headers:[], rows:[]})
const [loading,setLoading] = useState<boolean>(false)

useEffect(() => {
    setLoading(true)
    const currentDate1 = new Date();
    let year = currentDate1.getFullYear();
    let month = currentDate1.getMonth() + 1;
    let day = currentDate1.getDate();

    let queryObj: any = {
        startYear: year,
        startMonth: month,
        startDay: day,
        location: filter?.location?.id || 'All'  // Assuming location works fine
    };
    const startOfWeek = new Date(currentDate1);
    startOfWeek.setDate(currentDate1.getDate() - day);
    startOfWeek.setHours(0, 0, 0, 0);


    const now = new Date();
    const dayOfWeek = now.getDay(); // Day of week (0-6, 0 is Sunday)
    const currentDate = now.getDate(); // Day of month (1-31)
    const currentMonth = now.getMonth(); // Month (0-11, 0 is January)
    const currentYear = now.getFullYear(); // Full year (YYYY)
    const getFirstDayOfWeek = () => {
        return new Date(now.setDate(now.getDate() - dayOfWeek));
    };

    const daysInMonth = (year: any, month: any) => {
        return new Date(year, month + 1, 0).getDate();
    };

    const filterLogic: any = {
        'thisWeek': () => {
            queryObj.timeUnit = 'day';
            queryObj.span = 7;
            queryObj.startDay = getFirstDayOfWeek().getDate(); // Assuming week starts on Sunday
            queryObj.startMonth = currentMonth

        },
        'thisMonth': () => {
            queryObj.timeUnit = 'day';
            queryObj.span = daysInMonth(currentYear, currentMonth - 1);
            queryObj.startDay = 1; // Start from the first day of the current month
            queryObj.startMonth = currentMonth 

        },
        'yearToDate': () => {
            queryObj.timeUnit = 'month';
            const currentDate = new Date(); // Get the current date
            const currentYear = currentDate.getFullYear(); // Extract the current year
            const currentMonth = currentDate.getMonth();
            queryObj.span = currentMonth + 1;
            queryObj.startMonth = 0;
        },
        'lastWeek': () => {
            queryObj.timeUnit = 'day';
            queryObj.span = 7;
            queryObj.startDay = getFirstDayOfWeek().getDate() - 7; // Assuming week starts on Sunday
            queryObj.startMonth = currentMonth 
        },
        'lastMonth': () => {
            queryObj.timeUnit = 'day';
            const lastMonth = currentMonth - 1;
            queryObj.span = daysInMonth(currentYear, lastMonth);
            queryObj.startDay = 1; // Start from the first day of the last month
            queryObj.startMonth = lastMonth;
        },
        'last3Months': () => {
            queryObj.timeUnit = 'month';
            queryObj.span = 3;
            queryObj.startMonth = month - 3;
        },
        'last6Months': () => {
            queryObj.timeUnit = 'month';
            queryObj.span = 6;
            queryObj.startMonth = month - 6;
        },
        'last12Months': () => {
            queryObj.timeUnit = 'month';
            queryObj.span = 12;
            queryObj.startYear = year - 1
        }
    };

  // Use the selected filter to update the query object
  const selectedFilter = filter?.timeFrame?.id;
  if (selectedFilter && filterLogic[selectedFilter]) {
    filterLogic[selectedFilter]();
  }   
  API.post(url, queryObj)
      .then(({data}:any) => {
          setData(data?.data)
          setLoading(false)
      })
      .catch(err =>{
          console.log(err)
          setLoading(false)
      })
}, [filter]);
  
  return <ReportingTable loading = {loading} setLoading = {setLoading} headers={data?.headers || []} rows={data?.rows|| []} sumRowLabel={title} sheetName={sheetName}/>

};

export default ClientByLostReason;


