import React from 'react';
import { Box } from '@mui/material';
import Item from './Item';

const NestedItems = ({ items }) => {
  return (
    <Box p={1} bgcolor="#F8F9F9">
      {items.map((item, index) => (
        <Item key={index} item={item} level={0}/>
      ))}
    </Box>
  );
};

export default NestedItems;
