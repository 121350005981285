
import { Modal as MuiModal, Box, Button } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import { FormDatePicker } from 'components';

const modalBackdropStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const modalContentStyle: CSSProperties = {
    width: '25%',
    backgroundColor: 'background.paper',
    padding: 4,
    maxHeight: 'calc(100vh - 96px)',
    overflowY: 'auto',
};

export function InactiveDateModal({ open, closeHandler, title, styles, updateInactivDate }: any) {
    const [inDate, setInDate] = useState(new Date());
    const handleUpdate = () => {
        updateInactivDate(inDate);
    };

    return (
        <MuiModal
            open={open}
            onClose={closeHandler}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div style={modalBackdropStyle}>
                <Box sx={styles ? { ...modalContentStyle, ...styles } : modalContentStyle}>
                    {title && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h2 className="fs-30 text-primary">{title}</h2>
                        </div>
                    )}

                    <FormDatePicker
                        name="inactiveDate"
                        label={`Inactive Date*`}
                        value={inDate}
                        onChange={() => console.log('dated is being chagned.')}
                        error={false}
                        helperText={""}
                        pickerOnChange={(newValue: any | null) => {
                            if (newValue) {
                                setInDate(newValue);
                            }
                        }}
                    />
                    <br></br>
                    <br></br>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => closeHandler()} variant="contained" sx={{ bgcolor: '#a3a3ab' }} type="button">
                            Cancel
                        </Button>
                        <Button
                            onClick={(e: any) => handleUpdate()}
                            variant="contained"
                            sx={{ bgcolor: 'var(--primary-color)' }}
                            type="button">
                            Update
                        </Button>
                    </div>
                </Box>
            </div>
        </MuiModal>
    );
}
