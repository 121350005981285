import { useState, useEffect } from 'react';
import { Layout } from 'components';
import { ApplicantsTable } from 'views/Recruiting/ApplicantsTable';
import { FilterHeader } from 'views';
import { useAuth } from 'hooks';
import { fetchStages, fetchSources } from 'lib';
import { toast } from 'react-toastify';
import { useFilter } from 'pages/Marketing/ReferralPartners/ReferralFilterContext';

export function Applicants() {
  const { user } = useAuth()
  const queryParameters = new URLSearchParams(window.location.search);
  const edit = queryParameters.get("successEdit")

  useEffect(() => {
    if (edit === "true") {
      toast.success('Successfully Edited Applicant')
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.delete('successEdit');
      window.history.replaceState({}, '', currentUrl.href);
    }
  }, [edit])
  const {applicantsFilter, updateApplicantsFilter, resetApplicantsFilter} = useFilter();

  
  const [stages, setStages] = useState<any[]>([]);
  const [sources, setSources] = useState<any[]>([]);
  const resetFilter = ()=> {
    resetApplicantsFilter();
  }

  useEffect(() => {
    fetchStages(setStages);
    fetchSources(setSources)
  }, []);
  

  return (
    <Layout>
      <FilterHeader setFilter={updateApplicantsFilter} filter={applicantsFilter} type="applicants" options={stages} resetFilter = {resetFilter}/>
      <ApplicantsTable filter={applicantsFilter} options={{ stages, sources }} />
    </Layout>
  );
}