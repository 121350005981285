


import { Container } from '@mui/system'
import { Layout } from 'components'
import { useAuth } from 'hooks'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { FilterHeader } from "views"
import { ReferralPartnersTabs } from 'views/ReferralPartners/ReferralPartnersTabs'


function ReferralPartners() {
    const [type, setType] = useState("Referral Partners")
    const queryParameters = new URLSearchParams(window.location.search);
    const tab = queryParameters.get("type")
    const edit = queryParameters.get("successEdit")
    const {user} = useAuth()
    const [update,setUpdate] = useState(0)

    const forceRefresh = ()=>{
        setUpdate(prev => prev + 1)
    }


    useEffect(()=>{
        if(edit === "true"){
            toast.success('Successfully Edited Referral Partner')
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.delete('successEdit');
            window.history.replaceState({}, '', currentUrl.href);
        }
        if(edit === "company"){
            toast.success('Successfully Edited Company')
            const currentUrl = new URL(window.location.href);
            currentUrl.searchParams.delete('successEdit');
            window.history.replaceState({}, '', currentUrl.href);
        }
    },[edit])

    const [filter, setFilter] = useState(
        {
            flag: false,
            referralPartner: { id: '', value: '' },
            companyName: { id: '', value: '' },
            status: { id: 'Active', value: 'Active' },
            companyType:{id:'All', value:'All'},
            location: { id: user?.location?._id, value: user?.location?.location },
            groupBy:{ id: 'None', value: 'None' },
        }
    )

    const resetFilter = () => {
        const defaultFilter = {
            flag: false,
            referralPartner: { id: '', value: '' },
            companyName: { id: '', value: '' },
            status: { id: 'Active', value: 'Active' },
            companyType:{id:'All', value:'All'},
            location: { id: user?.location?._id, value: user?.location?.location },
            groupBy:{ id: 'None', value: 'None' },
        }
        setFilter(defaultFilter)
    }
  
    useEffect(()=>{setFilter(filter => ({...filter,location:{ id: user?.location?._id, value: user?.location?.location }}))},[user])
    return (
        <Layout>
            <Container maxWidth= {false} sx={{ paddingTop:15, paddingLeft:'0'}}>
                <FilterHeader type={type} label=''  setFilter={setFilter} maxWidth = {false}  forceUpdate = {update}/>
                <ReferralPartnersTabs forceRefresh = {forceRefresh}  setType={setType} type={type} resetFilter={resetFilter} />
            </Container>
        </Layout>
    )
}

export default ReferralPartners