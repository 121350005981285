import { Card, CardHeader } from 'components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { uniqueId } from 'lib';
import { FilterHeader } from 'views/FilterHeader';
import { useFilter } from 'pages/Marketing/ReferralPartners/ReferralFilterContext';
import ProspectsChild from 'pages/Marketing/Prospects/ProspectsChild';
import { ProspectStatus } from './ProspectStatus';
import { ProspectsGrid } from './ProspectsGrid';
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  selectedTabIndex: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TABS = [
  {
    label: 'Prospects',
    value: 'marketingDailylog',
    Children: <ProspectsChild></ProspectsChild>,
  },
  {
    label: 'Snapshot',
    value: 'Snapshot',
    Children: <ProspectsGrid></ProspectsGrid>,
  },
  {
    label: 'Status',
    value: 'status',
    Children: <ProspectStatus></ProspectStatus>,
  },
];

function TabPanel(props: TabPanelProps) {
  const { children, selectedTabIndex, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={selectedTabIndex !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {selectedTabIndex === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProspectPagetabs = ({ HeaderTitle }: any) => {
  const tabs = TABS;
  const [value, setValue] = useState(tabs[0]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [expanded, setExpanded] = useState(true);
  // const { prospectFilter, updateProspectFilter } = useFilter()
  // const [stages, setStages] = useState<any[]>([]);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
    setValue(tabs[newValue]);
  };
  return (
    <Card maxWidth={false} style={{ pt: 3, width: '95%' }} key={uniqueId("card-")}>
      <CardHeader
        title={HeaderTitle}
        expanded={expanded}
        setExpanded={setExpanded}
        expandable={false}
        type={''}
        addIcon={false}
      />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={selectedTabIndex}
            onChange={handleChange}
            aria-label="scrollable auto tabs example">
            {tabs.map((tab: any, idx: number) => {
              return <Tab label={tab.label} {...a11yProps(idx)} />;
            })}
          </Tabs>
        </Box>
        {tabs.map((tab: any, idx: number) => {
          return (
            <TabPanel selectedTabIndex={selectedTabIndex} index={idx}>
              {value?.Children}
            </TabPanel>
          );
        })}
      </Box>
    </Card>
  );
};
export default ProspectPagetabs;