
import { useCallback, useEffect, useRef, useState } from 'react'
import FullCalendar, { EventClickArg, EventContentArg } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Card, CardHeader, Modal, NoData, Spinner } from 'components'
import { colorCode, generateUrl } from 'lib'
import CareManagerActivitiesModalContent from '../CareManagerActivitiesModalContent'
import rrulePlugin from '@fullcalendar/rrule'
import { generateRows } from '../generateRows'
import { RRule } from 'rrule'
import DoneIcon from '@mui/icons-material/Done';
import moment from 'moment'
import { IconButton } from '@mui/material'
import { DateTime } from "luxon";
import { FilterHeader } from 'views/FilterHeader';
import { useAuth, useCompany } from 'hooks';
import { CareManagerFilter } from 'typings';
import API from 'services/AxiosConfig';
import { toast } from 'react-toastify';
// interface careManagerCalendarProps {
//   activities?: any
//   fetchData: any
// }

export function CareManagerCalendar() {
  const { locations } = useCompany()
  const [events, setEvents] = useState<any>()
  const [openModal, setOpenModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState<any>()
  const [scrollPosition, setScrollPosition] = useState<any>()
  const calendarRef = useRef<FullCalendar | null>(null);
  const [calendarActivities, setCalendarActivities] = useState()

  const handleCloseModal = () => {
    fetchCalendarData()
    setOpenModal(false)
  }

  const { user } = useAuth()
  const [filter, setFilter] = useState<CareManagerFilter>({
    careManager: { id: '0', value: 'All' },
    dateRange: { id: 'All', value: 'All' },
    location: { id: user ? user?.location._id : '0', value: user ? user?.location?.location : 'All' },
    activity: { id: 'All', value: 'All' },
    status: { id: 'Open', value: 'Open' },
    carePartner: { id: 'All', value: 'All' },
    client: { id: 'All', value: 'All' },
    groupBy: { id: 'None', value: 'None' },

  });
  const fetchCalendarData = useCallback(() => {
    const url = generateUrl('care-manager-activity-event', { ...filter, status: { id: 'All', value: 'All' } }, '', '', locations);
    API.get(url)
      .then((rsp: any) => {
        const data = rsp.data.data;
        setCalendarActivities(data)
      })
      .catch((error: any) => {
        toast.error('Failed to load Care Manager Activities.');
        console.error(error);
      });
  }, [filter]);

  useEffect(() => {
    fetchCalendarData()
    return () => { }
  }, [filter])

  const generateEventTitle = (activityObj: any) => {

    let eventGuest = activityObj?.activity?.activity[1] === 'P' ? `${activityObj?.carePartner?.firstName || activityObj?.client?.firstName || ''} ${activityObj?.carePartner?.lastName || activityObj?.client?.lastName || ''}` : `${activityObj?.client?.firstName || activityObj?.carePartner?.firstName || ''} ${activityObj?.client?.lastName || activityObj?.carePartner?.lastName || ''}`

    if (eventGuest.length > 1) {
      return `${activityObj?.careManager?.firstName || ''} ${activityObj?.careManager?.lastName || ''} / ${eventGuest} : ${activityObj?.activity?.activity}`
    } else {
      return `${activityObj?.careManager?.firstName || ''} ${activityObj?.careManager?.lastName || ''} : ${activityObj?.activity?.activity}`
    }
  }

  const generateEvents = (data: any) => {
    data && setEvents(data.map((activityObj: any, i: number) => {
      const formattedDate = moment(activityObj?.date.replace(/-/g, '\/').replace(/T.+/, ''))
      const time = moment(activityObj.date)
      formattedDate.set({
        hour: time.get('hour'),
        minute: time.get('minute')
      })
      const newestDate = new Date(formattedDate.toISOString())

      let event: any = {
        title: generateEventTitle(activityObj),
        id: i,
        _id: activityObj?._id,
        date: newestDate,
        // start: new Date(activityObj?.date.replace(/-/g, '\/').replace(/T.+/, '')),
        allDay: false
        ,
        textColor: colorCode(activityObj.date),
        extendedProps: { status: activityObj.active }
      }
      let today = moment()
      // activityObj?.recurrence?.type && activityObj?.recurrence?.type !== "No Recurrence" && (event.rrule = {
      //   freq: activityObj.recurrence.type === 'Monthly' ? RRule.MONTHLY : activityObj.recurrence.type,
      //   interval: Number(activityObj.recurrence.options),
      //   dtstart: activityObj.date,
      //   until: moment(activityObj.recurrence.endDate).add(1, 'day') || today.add(1, 'y')
      // })
      // if (activityObj?.recurrence?.type === 'Weekly') {
      //   activityObj?.recurrence?.days.length > 0 && (event.rrule.byweekday = activityObj?.recurrence?.days)
      // }

      return event
    }))
    if (calendarRef.current && selectedRow) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(new Date(selectedRow.date.value));
      setTimeout(() => {
        window.scrollTo(0, scrollPosition || 0);
      }, 500);
    }
  }
  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <div className="hover event-content" >
        {!eventContent?.event?._def?.extendedProps?.status && <IconButton
          sx={{
            background: 'green',
            color: 'white',
            cursor: "default",
            height: '1em',
            width: '1em'
          }}
          disableRipple
          disableFocusRipple
          className="hired"
          aria-label="Hired"
        >
          <DoneIcon />
        </IconButton>} <strong className='event-time'>{eventContent.timeText + 'm'}</strong>
        <p style={{ fontSize: 'larger', color: eventContent.textColor, margin: '5px', }}>{eventContent.event.title}</p>
      </div>
    )
  }

  const handleEventClick = (clickInfo: EventClickArg) => {
    setSelectedRow(generateRows([calendarActivities && calendarActivities[clickInfo.event._def.publicId]])[0])
    setOpenModal(true)
  }

  useEffect(() => {
    generateEvents(calendarActivities)
    return () => { }
  }, [calendarActivities])
  const updateEventDate = (values: any, date: any) => {
    //toast.loading('Updating care manager task...');

    API.put(`/care-manager-activity-event/update-date`, { date: date, id: values, userId: user?._id })
      .then((rsp) => {
        toast.dismiss();
        if (rsp.data.success) {

          //setTimeout(() => {
            window.scrollTo(0, scrollPosition || 0);
            toast.success('Successfully updated care manager task.');
            //fetchData()
         //}, 500);
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error('Failed to edit care manager task.');
        console.error(error);
      });
  };
  const handleEventDrop = (info: any) => {

    const updatedEvents = events.map((event: any) => {
      if (event.id === parseInt(info.event.id, 10)) {
        //api call 
        //setScrollPosition(window.scrollY.toString());
        window.scrollTo(0, scrollPosition || 0);
        updateEventDate(info.event.extendedProps._id, info.event.startStr);
        return {
          ...event,
          start: info.event.startStr
        };
      }
      return event;
    });
    setEvents(updatedEvents);
  };
  const handleEventDragStart = () => {
    setScrollPosition(window.scrollY.toString());
  };
  return (
    <Card >
      <FilterHeader type="careManagement" setFilter={setFilter} filter={filter} />
      <CardHeader title="Calendar Report" setOpenModal={() => { }} expanded={true} setExpanded={() => { }} addIcon={false} expandable={false} />
      <Modal open={openModal} closeHandler={handleCloseModal} title="Edit Care Manager Activity"
        radioGroup={true}
        radioGroupLabel1='Completed'
        radioGroupLabel2='Incomplete'
        currentRow={selectedRow}
      >
        <CareManagerActivitiesModalContent
          closeHandler={handleCloseModal}
          selected={selectedRow}
          data={selectedRow}
          renderButtons
        />
      </Modal>
      {!events && <Spinner />}
      {(events?.length === 0 || locations.length === 0) && <NoData />}
      {events?.length > 0 && locations.length > 0 &&
        <FullCalendar
          plugins={[dayGridPlugin, rrulePlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next',
            center: 'title',
            right: 'today'
          }}
          contentHeight='auto'
          selectable={false}
          initialView='dayGridMonth'
          editable={true}
          weekends={true}
          events={events}
          eventContent={renderEventContent} // custom render function
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
          eventDragStart={handleEventDragStart}
        />
      }
    </Card>
  )
}
