import React, { Fragment, useState } from 'react';
import { TextField, InputAdornment, InputLabel, Button } from '@mui/material';

const DeleteInput = ({ handleDeleteSubmit, targetWord }: any) => {
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toUpperCase();
    setInputValue(value);
  };


  return (
    <Fragment>
      <div style={{ position: 'relative', height: '50%' }}>
        <InputLabel>Type {targetWord} to continue</InputLabel>
        <TextField
          value={inputValue}
          onChange={handleChange}
          style={{ fontSize: '16px', width: '75%' }}
          placeholder='DELETE'
        />
      </div>
      <Button variant='contained' 
      disabled={inputValue !== targetWord}
       onClick={handleDeleteSubmit} sx={{ position: 'relative', top: 0 }}>DELETE</Button>
    </Fragment>
  );
};

export default DeleteInput;