import React, { useState, useEffect } from 'react';
import API from 'services/AxiosConfig';
import {
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Button
} from '@mui/material';
import * as XLSX from 'xlsx';
import { NoData, Spinner } from 'components';
import { formatQueryObj } from '../common';

const data = {
    headers: [],
    section1: [
        {
            label: 'Beginning Prospects',
            values: []
        },
        {
            label: 'New Prospects',
            values: []
        }
    ],
    section2: [
        {
            label: 'Prospects Won',
            values: []
        },
        {
            label: 'Prospects Lost',
            values: []
        }
    ]
};
interface Props { filter: any }
interface RowData {
    label: string;
    values: Array<number | string>;
}

function ApplicantsReportingTable(props: Props) {
    const { filter } = props
    const [loading, setLoading] = useState<boolean>(false)
    const [tableData, setTableData] = useState<any>(data)
    const headers = ['Applicants Activity', ...tableData?.headers || '', 'Total'];
    function calculateSum(section: any) {
        return section.reduce((totals: any, row: any) => {
            row.values.forEach((value: any, i: any) => {
                totals[i] = (totals[i] || 0) + value;
            });
            return totals;
        }, []);
    }

    const setColumnWidths = (ws: any, numOfCols: number, defaultWidth: number) => {
        const colWidths = Array.from({ length: numOfCols }, () => ({ wpx: defaultWidth }));
        ws['!cols'] = colWidths;
    };
    useEffect(() => {
        setLoading(true)
        const queryObj = formatQueryObj(filter)
        API.post('/applicants/reporting/monthly-beginning-prospects', queryObj)
            .then(({ data }: any) => {
                setTableData(data?.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [filter]);

    const exportToExcel = () => {
        console.log(data)
        setLoading(true)
        const copy:any = tableData.section1.slice()
        const copy2:any = tableData.section2.slice()
        const totalRow = {
            label: 'Total Applicants',
            values: tableData?.headers?.map((_: any, i: any) => {
                const beginningClients = copy?.find((row: any) => row.label === 'Beginning Applicants')?.values[i] || 0;
                const newClients = copy?.find((row: any) => row.label === 'New Applicants')?.values[i] || 0;
                // const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                return (beginningClients + newClients) 
            })
        };
        const totalRow2 = {
            label: 'Ending Applicants',
            values: tableData?.headers?.map((_: any, i: any) => {
                const beginningClients = copy?.find((row: any) => row.label === 'Beginning Applicants')?.values[i] || 0;
                const newClients = copy?.find((row: any) => row.label === 'New Applicants')?.values[i] || 0;
                const applicantsHired = copy2?.find((row: any) => row.label === 'Applicants Hired')?.values[i] || 0;
                const applicantsLost = copy2?.find((row: any) => row.label === 'Applicants Lost')?.values[i] || 0;
                // const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                return (beginningClients + newClients) - (applicantsHired + applicantsLost)
            })
        };
        const totalRow3 = {
            label:'Win / Loss Ratio',
            values: tableData?.headers?.map((_: any, i: any) => {
                const applicantsHired = copy2?.find((row: any) => row.label === 'Applicants Hired')?.values[i] || 0;
                const applicantsLost = copy2?.find((row: any) => row.label === 'Applicants Lost')?.values[i] || 0;
                // const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                let ratio = applicantsHired / applicantsLost
                ratio = !isNaN(ratio) ? ratio : 0
                return  Number(ratio.toFixed(2))
            })
        }
        const totalRow4 = {
            label:'Win Percentage',
            values: tableData?.headers?.map((_: any, i: any) => {
                const applicantsHired = copy2?.find((row: any) => row.label === 'Applicants Hired')?.values[i] || 0;
                const applicantsLost = copy2?.find((row: any) => row.label === 'Applicants Lost')?.values[i] || 0;
                // const lostClients = copy?.find((row: any) => row.label === 'Lost Clients')?.values[i] || 0;
                let ratio = applicantsHired  / (applicantsLost + applicantsHired)
                ratio = !isNaN(ratio) ? ratio : 0
                ratio *= 100
                return  ratio.toFixed(2) +'%'
            })
        }
        copy.push(totalRow)
        copy2.push(totalRow2,totalRow3,totalRow4)
        const ws = XLSX.utils.json_to_sheet([...copy.map((row:any) => {
          let obj: { [key: string]: number | string } = {};
          // Use the first value of headers for the label
          obj[headers[0]] = row.label;
          // Map values to the other headers
          row.values.forEach((value:any, index:number) => {
            obj[headers[index + 1]] = value; // '+1' to skip the first header
          });

          return obj;
        }),
        ...copy2.map((row:any) => {
            let obj: { [key: string]: number | string } = {};
            // Use the first value of headers for the label
            obj[headers[0]] = row.label;

            // Map values to the other headers
            row.values.forEach((value:any, index:number) => {
              obj[headers[index + 1]] = value; // '+1' to skip the first header

            });

            return obj;
          })
    ]);

        setColumnWidths(ws, headers.length, 100);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `Applicant_Activity.xlsx` || "data.xlsx");
        setLoading(false)
    }

    function calculateRow(section: any, section2: any) {

        return tableData?.headers?.map((_: any, i: any) =>
            section.reduce((total: any, row: any) => total + (row.values[i] || 0), 0))
    }
    function renderEndingProspectsRow(label: any, section: any, section2: any) {
        let totalProspects = calculateRow(section2, section)
        let totalRow = {
            label: label,
            values: tableData?.headers?.map((_: any, i: any) =>
                section.reduce((total: any, row: any) => total + (row.values[i] || 0), 0))
        };

        return (
            <TableRow key={label} style = {{borderTop:'2px solid black'}}>
                {totalRow.values.map((value: any, j: any) =>
                    <TableCell key={j} align="right" style={{ fontWeight: 'bold' }}>{totalProspects[j] - value}</TableCell>
                )}
            </TableRow>
        );
    }


    function renderSumRow(label: any, section: any) {
        const totalRow = {
            label: label,
            values: tableData?.headers?.map((_: any, i: any) =>
                section.reduce((total: any, row: any) => total + (row.values[i] || 0), 0))
        };
        return (
            <TableRow key={label} style = {{borderTop:'2px solid black'}}>
                {totalRow.values.map((value: any, j: any) =>
                    <TableCell key={j} align="right" style={{ fontWeight: 'bold'/*,borderTop:'2px solid black' */}}>{value}</TableCell>
                )}
               
            </TableRow>
        );
    }

    function renderWinLossRatioRow(section: any) {
        const winRow = section.find((row: any) => row.label === 'Applicants Hired');
        const lossRow = section.find((row: any) => row.label === 'Applicants Lost');

        if (!winRow || !lossRow) return null;

        const ratioRow = {
            label: 'Win / Loss Ratio',
            values: tableData?.headers?.map((_: any, i: any) => {
                const winValue = parseFloat(winRow.values[i]) || 0;
                const lossValue = parseFloat(lossRow.values[i]) || 0;

                return lossValue !== 0 ? (Number(winValue) / Number(lossValue)).toFixed(2) : 0;
            })
        };

        return renderRow(ratioRow, ratioRow.label, true);
    }

    function renderWinPercentageRow(section: any) {
        const winRow = section.find((row: any) => row.label === 'Applicants Hired');
        const lossRow = section.find((row: any) => row.label === 'Applicants Lost');

        if (!winRow || !lossRow) return null;

        const percentageRow = {
            label: 'Win Percentage',
            values: tableData?.headers?.map((_: any, i: any) =>
                (winRow.values[i] || 0) + (lossRow.values[i] || 0) !== 0 ? (winRow.values[i] || 0) / ((winRow.values[i] || 0) + (lossRow.values[i] || 0)) * 100 : 0)
        };

        return renderRow(percentageRow, percentageRow.label, true);
    }

    function prepareDataForExcel(rowData: RowData[], headers: string[]): { [key: string]: number | string }[] {
        return rowData.map(row => {
            let ytd = 0;
            const obj: { [key: string]: number | string } = { label: row.label };
            row.values.forEach((value, index) => {
                if (typeof value === "number") {
                    ytd += value;
                }
                obj[headers[index]] = value;
            });
            obj['Total'] = ytd;
            return obj;
        });
    }
    function renderRow(row: any, i: any, isBold = false) {
        return (
            <TableRow key={i}>
                {row.values.map((value: any, j: any) =>
                    <TableCell key={j} align="right" style={{ fontWeight: isBold ? 'bold' : 'normal' }}>{row.label === 'Win Percentage' ? value.toFixed(2) + '%' : value}</TableCell>
                )}
               
            </TableRow>
        );
    }
    function renderFirstColumn(row: any, i: any, isBold = false,border=false) {
        return (
            border==true?
            <>
                <TableRow key={i+"_"} style={{ width: '50px', /*borderTop:'2px solid black'*/borderTop :border ? '2px solid black' : 'none'}}>
                </TableRow>
                <TableRow key={i} style={{ width: '50px', /*borderTop:'2px solid black'*/borderTop :border ? '2px solid black' : 'none'}}>
                    <TableCell component="th" scope="row" style={{ fontWeight: isBold ? 'bold' : 'normal'/*,borderTop :border ? '2px solid black' : 'none'*/}}>{row.label}</TableCell>
                </TableRow>
            </>
            :
            <TableRow key={i} style={{ width: '50px', /*borderTop:'2px solid black'*/borderTop :border ? '2px solid black' : 'none'}}>
                <TableCell component="th" scope="row" style={{ fontWeight: isBold ? 'bold' : 'normal'/*,borderTop :border ? '2px solid black' : 'none'*/}}>{row.label}</TableCell>
            </TableRow>
        );
    }

    return (
        <div style={{ width: '100%', maxWidth: '1700px', marginBottom: '4%', marginTop: '10%' }}>
            <Button variant="contained" color="primary" style={{ float: 'right', margin: 0, marginBottom: '5px' }} onClick={exportToExcel} >
                Download Data
            </Button>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', maxWidth: '1700px' }}>
                <TableContainer  component = {Paper}  style={{ width: '375px', borderRadius:'4px 0 0 4px' ,overflow:'hidden'}}>
                    <MuiTable>
                        <TableHead>
                            <TableRow style={{ minWidth: '200px' }}>
                                <TableCell style={{ backgroundColor: '#2f374e', color: 'white',minHeight:'26px' }} align={'left'} >Applicants Activity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                // Render only the Spinner when loading
                                <TableRow>
                                    <TableCell
                                        colSpan={Math.min(headers.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <Spinner />
                                    </TableCell>
                                </TableRow>
                            ) : tableData?.section?.length === 0 ? (
                                // Render 'No Data' component or message if there are no rows and not loading
                                <TableRow style={{ width: '100%' }}>
                                    <TableCell
                                        colSpan={Math.min(headers.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <NoData />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {[...tableData?.section1,
                                    { label: 'Total Applicants', bold: true,border:true  },
                                    ...tableData?.section2,
                                    { label: 'Ending Applicants', bold: true,border:true },
                                    { label: 'Hire / Loss Ratio', bold: true,border:false },
                                    { label: 'Hire Percentage', bold: true,border:false },

                                    ]?.map((row: any, i: any) => renderFirstColumn(row, i, row.bold,row.border))}

                                    <TableRow />
                                </>
                            )
                            }
                        </TableBody>
                    </MuiTable>
                </TableContainer>
                <TableContainer  component = {Paper} style = {{ borderRadius:'0 4px 4px 0'}}>
                    <MuiTable>
                        <TableHead>
                            <TableRow>
                                {headers.slice(1,-1).map((header, i) =>
                                    <TableCell style={{ backgroundColor: '#2f374e', color: 'white', minWidth: i === 0 ? '200px' : '75px' }} key={i} align={'right'} >{header}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                // Render only the Spinner when loading
                                <TableRow>
                                    <TableCell
                                        colSpan={Math.min(headers.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <Spinner />
                                    </TableCell>
                                </TableRow>
                            ) : tableData?.section?.length === 0 ? (
                                // Render 'No Data' component or message if there are no rows and not loading
                                <TableRow style={{ width: '100%' }}>
                                    <TableCell
                                        colSpan={Math.min(headers.length * 2, 18)}
                                        style={{ textAlign: 'center', padding: '20px' }}
                                    >
                                        <NoData />
                                    </TableCell>
                                </TableRow>
                            ) : (
                                <>
                                    {tableData.section1.map((row: any, i: any) => renderRow(row, i))}
                                    <TableRow sx={{ borderTop: "2px solid black" }} />
                                    {renderSumRow('Total Applicants', tableData.section1)}
                                    {tableData?.section2?.map((row: any, i: any) => renderRow(row, i))}
                                    <TableRow sx={{ borderTop: '2px solid #2f374e' }} />
                                    {renderEndingProspectsRow('Ending Applicants', tableData.section2, tableData.section1)}
                                    {renderWinLossRatioRow(tableData.section2)}
                                    {renderWinPercentageRow(tableData.section2)}
                                </>
                            )
                            }

                        </TableBody>
                    </MuiTable>
                </TableContainer>
            </div>
        </div>
    );
}

export default ApplicantsReportingTable

