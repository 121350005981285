import React from 'react';
import { TableCell, TableRow } from "@mui/material";

const SubRowComponent = ({ subRow, index, delta, calculateTotal, toggleVisible }: any) => {
    return (
        <TableRow onClick ={toggleVisible} key={`sub-${index}`} style={{ color: 'lightblue', fontSize: 'smaller',cursor:'pointer' }}>
              <TableCell style={{ paddingLeft: '4ch', color: '#4768a5', fontSize: 'smaller' }}>
                {subRow.label}
            </TableCell>
            {subRow?.values.map((value: any, i: number) => (
             <TableCell sx={{padding:0}} key={i} align="center" style={{  color: '#4768a5', borderRight: i % 2 === 0 ? '1px dotted grey' : '1px solid grey', borderLeft: i === 0 ? '1px solid grey' : '', }}>
                    {value}
                </TableCell>
            ))}
            {!delta && calculateTotal(subRow, index, true)}
        </TableRow>
    );
};

export default SubRowComponent;
