import React from 'react'
import ClientBySourceTable from './ClientBySourceTable'
import ClientActivityTable from './ClientActivityTable'
import ClientByLostReason from './ClientByLostReason'

interface Props { filter: any }

function ClientsReportingTables(props: Props) {
  const { filter } = props
    return (
        <div>
      <ClientActivityTable filter = {filter} />
      {/* <ClientBySourceTable filter = {filter} sheetName = 'New Clients by Referral Type'/> */}
      <ClientByLostReason filter = {filter} url = '/client/monthly-lost-client' title = "Total Lost Clients" sheetName = 'Lost Clients by Reason'/>
      <ClientByLostReason filter = {filter} url = '/client/monthly-lost-client-tenure' title = "Total Lost Clients" sheetName = 'Lost Clients Tenure (days)'/>
        </div>
    )
}

export default ClientsReportingTables
