import { Modal as MuiModal, Box, FormControl, Button, FormHelperText, Select, InputLabel, MenuItem } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import { FormDatePicker } from 'components';

const modalBackdropStyle: CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const modalContentStyle: CSSProperties = {
    width: '25%',
    backgroundColor: 'background.paper',
    padding: 4,
    maxHeight: 'calc(100vh - 96px)',
    overflowY: 'auto',
};
export function ProspectInactive({ lostClientReasons, title, handleUpdateClientReason, lastReason, lastDate, open, closeMe, styles = "", }: any) {
    const [reason, setReason] = useState(lastReason);
    const [inDate, setInDate] = useState(lastDate);
    const [error, setError] = useState(false);
    const doUpdate = (reason: any, inDate: any) => {
        if (Object.keys(reason).length === 0) {
            setError(true);
        }
        else {
            setError(false);
            handleUpdateClientReason(reason, inDate);
        }

    }
    return (
        <MuiModal
            open={open}
            onClose={closeMe}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div style={modalBackdropStyle}>
                <Box sx={styles ? { ...modalContentStyle, ...styles } : modalContentStyle}>
                    {title && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <h2 className="fs-30 text-primary">{title}</h2>
                        </div>
                    )}

                    <FormControl fullWidth sx={{ marginBottom: '10px' }}>
                        <InputLabel /*style={{ color: formik.touched.lostClientReason && formik.errors.lostClientReason ? 'red' : '' }}*/ id="demo-simple-select-label">Lost Client Reason</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={reason}
                            label="Lost Client Reason"
                            name="lostClientReason"
                            error={error}
                            onChange={(e) => {
                                setReason(e.target.value)
                                setError(false)
                            }}
                        >
                            {lostClientReasons.map((item: any) => (
                                <MenuItem value={item.type}>{item.type}</MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText style={{ color: 'red' }}>
                            Lost Client Reason is required
                        </FormHelperText>}
                    </FormControl>

                    <FormDatePicker
                        name="inactiveDate"
                        label={`Inactive Date*`}
                        value={inDate}
                        onChange={() => console.log('dated is being chagned.')}
                        error={false}
                        helperText={""}
                        pickerOnChange={(newValue: any | null) => {
                            if (newValue) {
                                setInDate(newValue);
                            }
                        }}
                    />

                    <br></br>
                    <br></br>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={() => closeMe()} variant="contained" sx={{ bgcolor: '#a3a3ab' }} type="button">
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ bgcolor: 'var(--primary-color)' }}
                            type="button" onClick={() => doUpdate(reason, inDate)}>
                            Update
                        </Button>
                    </div>
                </Box>
            </div>
        </MuiModal>
    );
}
