// import axios from 'axios';

// const apiClient = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
// });

// apiClient.interceptors.request.use(
//   async (config) => {
//     const value = await localStorage.getItem('apexcare-token');
//     if (value) {
//       config.headers = {
//         Authorization: `Bearer ${value}`,
//       };
      
//       if(config)
//       {
//         config.headers['Access-Control-Allow-Origin'] = '*';
//         config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE,OPTIONS';
//         config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization';
//       }
//     }
//     // Add CORS headers
    
   
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   },
// );

// const API = {
//   get: (method: string) => {
//     return apiClient.get(method);
//   },
//   put(method: string, payload: any) {
//     return apiClient.put(method, payload);
//   },
//   post(method: string, payload: any) {
//     return apiClient.post(method, payload);
//   },
//   delete(method: string) {
//     return apiClient.delete(method);
//   },
// };

// export default API;

import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiClient.interceptors.request.use(
  async (config) => {
    const value = await localStorage.getItem('apexcare-token');
    if (value) {
      config.headers = {
        Authorization: `Bearer ${value}`,
      };
      if(config) {
        config.headers['Access-Control-Allow-Origin'] = '*';
        config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE,OPTIONS';
        config.headers['Access-Control-Allow-Headers'] = 'Origin, X-Requested-With, Content-Type, Accept, Authorization';
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

const retryWithExponentialBackoff = async (fn: () => Promise<any>, retries: number = 0, delayMs: number = 200): Promise<any> => {
  try {
    return await fn();
  } catch (error) {
    if (retries <= 0) throw error;
    await delay(delayMs);
    return retryWithExponentialBackoff(fn, retries - 1, delayMs * 2);
  }
};

const handleApiResponse = (res: any): any => {
  if (res && res.data !== undefined) {
    return res;
  } else {
    throw new Error('Invalid response data');
  }
};

const API = {
  get: async (method: string): Promise<any> => retryWithExponentialBackoff(
    () => apiClient.get(method).then(handleApiResponse)
  ),
  // put: async (method: string, payload: any): Promise<any> => retryWithExponentialBackoff(
  //   () => apiClient.put(method, payload).then(handleApiResponse)
  // ),
  // post: async (method: string, payload: any): Promise<any> => retryWithExponentialBackoff(
  //   () => apiClient.post(method, payload).then(handleApiResponse)
  // ),
  // delete: async (method: string): Promise<any> => retryWithExponentialBackoff(
  //   () => apiClient.delete(method).then(handleApiResponse)
  // ),
  put(method: string, payload: any) {
    return apiClient.put(method, payload);
  },
  post(method: string, payload: any) {
    return apiClient.post(method, payload);
  },
  delete(method: string) {
    return apiClient.delete(method);
  },
};

export default API;
