import { createContext, FC, useContext, useState, useEffect } from 'react';

interface ModalContextState {
  isModalOpen: string[];
  openModal: (modalName: string) => void;
  closeModal: (modalName: string) => void;
}

export const ModalContext = createContext<ModalContextState | undefined>(undefined);

export const ModalProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState<string[]>([]);

  const openModal = (modalName: string) => {
    setIsModalOpen((prev) => [...prev, modalName]);
  };

  const closeModal = (modalName: string) => {
    setIsModalOpen((prev) => prev.filter(name => name !== modalName));
  };

  useEffect(() => {
    // Check if any modals are open by looking if the array is not empty
    const anyModalOpen = isModalOpen.length > 0;
    //console.log('this is what youre looking for', anyModalOpen, isModalOpen);

    // Set the body overflow style based on whether any modal is open
    document.body.style.overflow = anyModalOpen ? 'hidden' : 'unset';

    // Cleanup function to unset the overflow when the component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  const providerValue = {
    isModalOpen,
    openModal,
    closeModal,
  };

  return <ModalContext.Provider value={providerValue}>{children}</ModalContext.Provider>;
};

export function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}