import "./App.css";
import DocumentList from "../../pages/Uploader/DocumentList";
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Sidebar from "../../pages/Uploader//Sidebar";
import DocumentViewer from "../../pages/Uploader/DocumentViewer";
import FolderViewer from '../../pages/Uploader/FolderViewer';
import { Layout, Card, CardHeader } from "components";
import AddFolderForm from "pages/Uploader/AddFolderForm";
import AddFileForm from "pages/Uploader/AddFileForm";
import { useEffect, useState } from "react";
import { Container } from "@mui/system";
import { useCompany } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import { fetchDocuments, fetchUploadSections } from "pages/Uploader/redux/actions/documentActions";


function DocumentUploader() {
    const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
    const [isFileModalOpen, setIsFileModalOpen] = useState(false);
    const [dtype, setDType] = useState('Folder')
    const handleCloseFolderModal = () => setIsFolderModalOpen(false);
    const handleCloseFileModal = () => setIsFileModalOpen(false);
    const [ddnSections, setDdnSections] = useState<any>([]);
    const [selectedLocation, setSelectedLocation] = useState('all');
    const [selectedSection, setSelectedSection] = useState('all');
    const [ddnLocations, setDdnLocations] = useState<any>([]);
    const dispatch = useDispatch();
    const { locations } = useCompany()

    const handleFolderSubmit = () => {
        handleCloseFolderModal();
    };
    const handleFileSubmit = () => {
        handleCloseFileModal();
    };
    const handleOpenModal = (result: boolean) => {
        if (dtype === "Folder") {
            setIsFolderModalOpen(result)
        }
        else if (dtype === "File") {
            setIsFileModalOpen(result)
        }
    };
    useEffect(() => {
        dispatch(fetchDocuments(selectedSection));
        console.log("selected section--"+selectedSection)
    }, [selectedSection, dispatch]);
    useEffect(() => {
        dispatch(fetchUploadSections());
    }, [dispatch]);

    const { fileSections, fetchDocumentsLoading } = useSelector((state: any) => state.documents);
    useEffect(() => {
        if (fileSections.length > 0) {
            const mappedData = fileSections.map((item: any) => ({
                key: item._id,
                label: item.type
            }));
            const d = [{ key: "all", label: 'All' }, ...mappedData]
            setDdnSections(d);
        }
        const data = [{ _id: "all", location: 'All' }, ...locations]
        setDdnLocations(data);
    }, [fileSections, fetchDocumentsLoading, dispatch, locations]);
    return (
        <Layout>
            <Box style={{ paddingTop: '140px' }} ></Box>
            <section style={{paddingTop:'35px'}}>
                <Container maxWidth={'xl'} >
                    {/* <Box sx={{ my: true ? 4 : 8 }}>{true && <h2 className="pt">{true}</h2>}</Box> */}
                    <Box sx={{ boxShadow: 4, p: 2, display: 'flex', flexGrow: 1, width: 'auto', marginLeft:  '0px' }}>
                        <form style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'left', gap: '25px', flexWrap: 'unset', width: '100%' }}>
                            {/* <Box sx={{ width: '20%' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>Locations</InputLabel>
                                    <Select
                                        MenuProps={{ disableScrollLock: true }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Locations"
                                        value={selectedLocation}
                                        onChange={(e) => setSelectedLocation(e.target.value)}
                                        name="locaion"
                                        displayEmpty
                                        sx={{ width: '100%' }}
                                    >
                                        {ddnLocations?.map((item: any) => {
                                            let divide = item?.divider || item.label === 'All'
                                            const content = [<MenuItem key={item._id} value={item._id} divider={divide}>{item.location}</MenuItem>];
                                            return content
                                        })}
                                    </Select>
                                </FormControl>
                            </Box> */}
                            <Box sx={{ width: '20%' }}>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel>Sections</InputLabel>
                                    <Select
                                        MenuProps={{ disableScrollLock: true }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Sections"
                                        value={selectedSection}
                                        onChange={(e) => setSelectedSection(e.target.value)}
                                        name="section"
                                        displayEmpty
                                        sx={{ width: '100%' }}
                                    >
                                        {ddnSections?.map((item: any) => {
                                            let divide = item?.divider || item.label === 'All'
                                            const content = [<MenuItem key={item.key} value={item.key} divider={divide}>{item.label}</MenuItem>];
                                            return content
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            {/* <Box sx={{ mb: 0.3, display: 'flex', alignItems: 'flex-end' }} key={20}>
                                <Button
                                    variant="contained"
                                // onClick={(e) => {
                                //     setFilter({
                                //         ...filter,
                                //         ...tempFilter,
                                //     });
                                // }}
                                >
                                    Search
                                </Button>
                            </Box> */}
                        </form>
                    </Box>
                </Container>
            </section>
            <Card >
                <CardHeader
                    setOpenModal={handleOpenModal}
                    expanded={false}
                    setExpanded={() => null}
                    expandable={false}
                    type={dtype}
                    radioGroupLabel1='File'
                    radioGroupLabel2='Folder'
                    radioGroup={false}
                    title="File HUB"
                    setType={setDType}
                    renderAll={false}
                    addIcon={false}
                />
                <Box >
                    <div>
                        {/* <Container > */}
                        <Box style={{ display: 'flex', flexDirection: 'row', gap: '15px', paddingLeft: '16px' }}>
                            <Box
                                sx={{
                                    width: '300px',
                                    flexShrink: 0
                                }}
                            >
                                <Sidebar>
                                    <DocumentList></DocumentList>
                                </Sidebar>
                            </Box>
                            <Box sx={{ flexShrink: 1, width: '100%', overflowY: 'auto', }}>
                                <FolderViewer />
                                <DocumentViewer />
                            </Box>
                        </Box>
                        {/* </Container> */}
                    </div></Box></Card>
            {/* <AddFolderForm
                open={isFolderModalOpen}
                onClose={handleCloseFolderModal}
                onFolderAdded={handleFolderSubmit}
            />
            <AddFileForm
                open={isFileModalOpen}
                onClose={handleCloseFileModal}
            /> */}
        </Layout>
    );
}

export default DocumentUploader;