
import { useCompany, useAuth, } from 'hooks';
import { formatName } from 'lib';
import { InputGroup } from './InputGroup';
import React, { useEffect, useState } from 'react'
import API from 'services/AxiosConfig';
import ReferralPartners from 'pages/Marketing/ReferralPartners/ReferralPartners';
import { useFilter } from '../../pages/Marketing/ReferralPartners/ReferralFilterContext';
import { useParams } from 'react-router-dom';

interface FilterHeaderProps {
  type: string;
  filter?: any;
  setFilter: (filter: any) => void;
  label?: string;
  options?: any;
  maxWidth?: any;
  forceUpdate?: any;
  resetFilter?: any;
}

export const FilterHeader = ({ type, filter, setFilter, label = '', options, maxWidth = 'xl', forceUpdate }: FilterHeaderProps): JSX.Element => {

  const { referralFilter, prospectFilter, applicantsFilter, updateProspectFilter, updateFilter, resetFilter, updateApplicantsFilter, resetApplicantsFilter } = useFilter();
  const { locations, users, clients, carePartners, careManagerActivities, applicants } = useCompany();
  const locationSelect: any = {
    type: 'select',
    name: 'location',
    label: 'Location',
    items: [{ key: '0', label: locations.length === 0 ? 'None' : "All" }, ...locations.map((loc: any) => ({ key: loc._id, label: loc.location }))]
  };
  const [defaultPartners, setDefaultPartners] = useState([])
  const [partners, setPartners] = useState(defaultPartners)
  const [companies, setCompanies] = useState([])
  const [prospects, setProspects] = useState([])
  const [companyTypes, setCompanyTypes] = useState([])
  const [stageOptions, setStageOptions] = useState([])
  const [milestones, setMilestones] = useState([])
  const [activityTypes, setActivityTypes] = useState<any>([])
  const { prospectId } = useParams()

  const applicantsList: Array<any> = JSON.parse(localStorage.getItem('applicants') || '[]');

  const filterPartners = (id: string) => {
    const filtered = defaultPartners.filter((partner: any) => {
      return partner?.companyName?._id === id
    })
    setPartners(filtered)
    return
  }
  const filterCompanies = (id: string) => {
    if (id != "") {
      const filtered = companies.filter((company: any) => {
        return company?._id === id
      })
      setPartners(filtered)

    } return
  }

  const resetPartners = () => {
    setPartners(defaultPartners)
  }

  const transformDataToStages = (data: any) => {
    const result = data.progress.map((progressItem: any) => {
      return {
        key: progressItem.cardName._id,
        label: progressItem.cardName.stage,
        // subItems: progressItem.cardItems.map((cardItem: any) => {
        //   return {
        //     key: cardItem._id,/*|| cardItem.label.toLowerCase().replace(/\s+/g, '_'),*/
        //     label: cardItem.label,
        //   };
        // }),
      };
    });
    console.log("transformDataToStages--");
    console.log(result);
    return result;
  };
  const transformDataToMilestones = (data: any) => {
    const result = data.progress.flatMap((progressItem: any) => {
      return progressItem.cardItems.map((cardItem: any) => {
        return {
          //key: cardItem._id || cardItem.label.toLowerCase().replace(/\s+/g, '_'),
          key: cardItem.label.toLowerCase().replace(/\s+/g, '_'),
          label: cardItem.label,
          stage: progressItem?.cardName?.stage
        };
      });
    });
    console.log("transformDataToMilestones--");
    console.log(result);
    return result;
  };
  const getMilestoneByStage = (cardIds: any) => {
    // const milestone = stageOptions.filter(
    //   (card: any) => card.cardName._id === cardId
    // );
    const milestone = cardIds.length === 0 ? stageOptions : stageOptions.filter(
      (card: any) => cardIds.includes(card?.key) || cardIds.includes('All')
    );

    //setMilestones(milestone);
  }
  const getStageOptions = async () => {
    try {
      // const p = await API.get('/stage-options/activity/search/Active')
      const sp = await API.get('/prospects/progress');
      console.log('sp.data.data')
      console.log(sp.data.data)
      const dataStages = transformDataToStages(sp.data.data);
      const dataMilestone = transformDataToMilestones(sp.data.data);
      setStageOptions(dataStages);
      setMilestones(dataMilestone);
    } catch (err) {
      console.log(err)
    }
  }

  const fetchCompaniesTypes = async () => {
    try {
      const comp = await API.get('referral-partners/types/listing')
      comp && (setCompanyTypes(comp.data.data))
    } catch (err) {
      console.log(err)
    }

  }

  const getReferralPartners = async () => {
    try {
      const p = await API.get('referral-partners')
      setPartners(p.data.data)
      setDefaultPartners(p.data.data)
    } catch (err) {
      console.log(err)
    }
  }
  const getActivityTypes = async () => {
    try {
      let t = true;
      if (prospectId) {
        t = false
      }
      const p = await API.get(`marketing/types?referral=${t}`)
      setActivityTypes(p.data.data)
    } catch (err) {
      console.log(err)
    }
  }
  const getAllProspects = async () => {
    try {
      const location = 0;
      //const p = await API.get(`prospects/ddn/${location}`);
      //const p = await API.get('prospects/ddn')
      const p = await API.get('prospects/all')
      setProspects(p.data.data)
    } catch (err) {
      console.log(err)
    }
  }
  const getCompanies = async () => {
    try {
      const p = await API.get('referral-partners/companies/listing')
      setCompanies(p.data.data)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (type === 'marketing-activities' || type === 'marketingManagement' || type === 'Referral Partners' || type == 'referralReporting') {
      getReferralPartners()
      getCompanies()
      getActivityTypes();
    }
    if (type === 'prospects' || type === 'marketingManagement') {
      getAllProspects()
      getStageOptions()
    }
    if (type === 'Companies' || type === 'referralReporting') {
      fetchCompaniesTypes()
    }
  }, [type, forceUpdate])
  if (type === 'quickHits') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label={label}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap' }}
        inputs={[
          { type: 'checkbox', name: 'flag', label: 'Flag' },
          locationSelect,
          {
            type: 'autoComplete',
            name: 'careManager',
            label: 'Care Manager',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: '0', label: 'All' },
              ...users.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id,data:user })),
            ],
          },
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'Open', label: 'Open' },
              { key: 'Closed', label: 'Closed' },
            ],
          },
          {
            type: 'select',
            name: 'dateRange',
            label: 'Date Range',
            items: [
              { key: 'All', label: 'All' },
              { key: '7', label: 'Last 7 Days' },
              { key: '14', label: 'Last 14 Days' },
              { key: '30', label: 'Last 30 Days' },
              { key: 'Custom', label: 'Custom' },
            ],
          },
          { type: 'customDateRange', condition: 'custom', conditionTarget: 'dateRange' },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'clients') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', gap: '25px' }}
        inputs={[
          {
            type: 'autoComplete',
            name: 'client',
            label: 'Client Name',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { label: '', id: '' },
              ...clients.map((client: any) => ({ label: formatName(client.lastName, client.firstName,/*+" ("+client?.location?.location+")"*/), item: client/*._id*/, data: client }))
            ]
          },
          locationSelect,
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'All', label: 'All' },
              { key: 'Active', label: 'Active' },
              { key: 'Inactive', label: 'Inactive' },
            ],
          },
          {
            type: 'autoComplete',
            name: 'careManager',
            label: 'Care Manager',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: '0', label: 'All' },
              ...users.map((user: any) => ({ label: formatName(user.firstName, user.lastName), item: user._id, data: user })),
            ],
          },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'carePartners') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start', gap: '25px' }}
        inputs={[
          {
            type: 'autoComplete',
            name: 'carePartner',
            label: 'Care Partner Name',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              // ...carePartners.map((carePartner: any) => ({ label: formatName(carePartner.firstName, carePartner.lastName), item: carePartner._id }))
              ...carePartners.map((carePartner: any) => ({ label: formatName(carePartner.lastName, carePartner.firstName), item: carePartner._id, data: carePartner }))
            ]
          },
          locationSelect,
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'All', label: 'All' },
              { key: 'Active', label: 'Active' },
              { key: 'Inactive', label: 'Inactive' },
            ],
          },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'applicants' && options?.length) {
    return (
      <InputGroup
        filter={applicantsFilter}
        setFilter={updateApplicantsFilter}
        type={'applicants'}
        resetFilter={resetApplicantsFilter}
        styles={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', justifyContent: 'center', gap: '25px' }}
        inputs={[
          { type: 'checkbox', name: 'flag', label: 'Flag' },
          // {
          //   type: 'textField',
          //   name: 'name',
          //   label: 'Name',
          //   styles: { minWidth: 250, maxWidth: '100%' },
          // },

          {
            type: 'autoComplete',
            name: 'name',
            label: 'Name',
            styles: { minWidth: 250, maxWidth: '100%' },
            // options: [
            //   // { label: '', id: '' },
            //   ...applicantsList.map((applicant: any) => ({ label:applicant.fullName , id: applicant._id ,data:applicant}))
            // ]
            options: [
              ...applicantsList.map((applicant: any) => ({ label: formatName(applicant.lastName, applicant.firstName), id: applicant._id, data: applicant }))
            ]

          },
          locationSelect,
          {
            type: 'select',
            name: 'status',
            label: 'Status',

            items: [
              { key: 'All', label: 'All' },
              { key: 'Active', label: 'Active' },
              { key: 'inactive', label: 'Inactive' },
            ],
          },
          {
            type: 'select',
            name: 'stage',
            label: 'Stage',
            items: [{ key: '', label: 'All' }, ...options.map((s: any) => {
              if (s.divider) {
                return { key: s.stage, label: s.stage, divider: true }
              }
              return ({ key: s.stage, label: s.stage })
            })],
          },
          {
            type: 'select',
            name: 'dateRange',
            label: 'Date Range',
            items: [
              { key: 'All', label: 'All' },
              { key: '7', label: 'Last 7 Days' },
              { key: '14', label: 'Last 14 Days' },
              { key: '30', label: 'Last 30 Days' },
              { key: 'Custom', label: 'Custom' },
            ],
          },
          { type: 'customDateRange', condition: 'custom', conditionTarget: 'dateRange' },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'careManagement') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label={'Care Management'}
        styles={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', justifyContent: 'center', gap: '25px' }}
        inputs={[{
          type: 'autoComplete',
          name: 'careManager',
          label: 'Care Manager',
          styles: { minWidth: 250, maxWidth: '100%' },
          options: [
            { key: '0', label: 'All' },
            ...users.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
          ],
        },
        {
          type: 'select',
          name: 'dateRange',
          label: 'Date Range',
          items: [
            { key: 'All', label: 'All' },
            { key: '-7', label: 'Next 7 Days' },
            { key: '-14', label: 'Next 14 Days' },
            { key: '-30', label: 'Next 30 Days' },
          ],
        },
          locationSelect,
        {
          type: 'select',
          name: 'activity',
          label: 'Activity',
          items: [
            { key: 'All', label: 'All' },
            ...careManagerActivities.map(({ activity, _id }: any) => ({
              key: _id,
              label: activity
            }))
          ],
        },
        {
          type: 'select',
          name: 'status',
          label: 'Status',
          items: [
            { key: 'All', label: 'All' },
            { key: 'Open', label: 'Open' },
            { key: 'Closed', label: 'Closed' },
          ],
        },
        {
          type: 'autoComplete',
          name: 'carePartner',
          label: 'Care Partner',
          styles: { minWidth: 250, maxWidth: '100%' },
          options: [
            { key: '0', label: 'All' },
            ...carePartners.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
          ],
        },
        {
          type: 'autoComplete',
          name: 'client',
          label: 'Client Name',
          styles: { minWidth: 250, maxWidth: '100%' },
          options: [
            { label: '', id: '' },
            ...clients.map((client: any) => ({ label: formatName(client.firstName, client.lastName), item: client._id, data: client }))
          ]
        },
        {
          type: 'select',
          name: 'groupBy',
          label: 'Group By',
          items: [
            { key: 'None', label: 'None' },
            { key: 'Care Manager', label: 'Care Manager' },
            { key: 'Activity', label: 'Activity' },
            { key: 'Client', label: 'Client' },
            { key: 'Care Partner', label: 'Care Partner' },
            { key: 'Week', label: 'Week' },
            { key: 'Day', label: 'Day' },
          ],
        },
        { type: 'button', label: 'Search' },
        ]}
      />
    )
  }
  if (type === 'noScheduleReport') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label=''
        styles={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', justifyContent: 'center', gap: '25px' }}
        inputs={[
          {
            type: 'select',
            name: 'numberOfDays',
            label: 'Number of Days',
            items: [
              { key: '1', label: '30 Days' },
              { key: '2', label: '60 Days' },
              { key: '3', label: '90 Days' },
              { key: '6', label: '180 Days' },
              { key: 'Custom', label: 'Custom' }
            ],
          },
          { type: 'customMonthField' },
          { type: 'button', label: 'Search' },
        ]}
      />
    )
  }
  if (type === 'prospectsReporting') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label=''
        styles={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', justifyContent: 'center', gap: '25px' }}
        inputs={[
          locationSelect,
          {
            type: 'select',
            name: 'timeFrame',
            label: 'Time Frame',
            items: [
              { key: 'thisWeek', label: 'This Week' },
              { key: 'thisMonth', label: 'This Month' },
              { key: 'yearToDate', label: 'Year-to-Date' },
              { key: 'lastWeek', label: 'Last Week' },
              { key: 'lastMonth', label: 'Last Month' },
              { key: 'last3Months', label: 'Last 3 Months' },
              { key: 'last6Months', label: 'Last 6 Months' },
              { key: 'last12Months', label: 'Last 12 Months' },
            ],
          },

          { type: 'button', label: 'Search' },
        ]}
      />
    )
  }

  if (type === 'referralReporting') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        filterPartners={filterPartners}
        resetPartners={resetPartners}
        label=''
        styles={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', justifyContent: 'center', gap: '25px' }}
        inputs={[
          { type: 'checkbox', name: 'flag', label: 'Flag' },
          locationSelect,
          {
            type: 'select',
            name: 'timeFrame',
            label: 'Time Frame',
            items: [
              { key: 'thisWeek', label: 'This Week' },
              { key: 'thisMonth', label: 'This Month' },
              { key: 'yearToDate', label: 'Year-to-Date' },
              { key: 'lastWeek', label: 'Last Week' },
              { key: 'lastMonth', label: 'Last Month' },
              { key: 'last3Months', label: 'Last 3 Months' },
              { key: 'last6Months', label: 'Last 6 Months' },
              { key: 'last12Months', label: 'Last 12 Months' },
            ],
          },
          {
            type: 'autoComplete',
            name: 'referralPartner',
            label: 'Referral Partner',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...partners.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
            ],
          },
          {
            type: 'autoComplete',
            name: 'company',
            label: 'Company',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...companies.map((user: any) => ({ label: user?.companyName, id: user._id, data: user })),
            ],
          },
          {
            type: 'select',
            name: 'companyType',
            label: 'Company Type',
            styles: { minWidth: 250 },
            items:
              [{ key: 'All', label: 'All' }, ...companyTypes.map((s: any) => ({ key: s._id, label: s.companyType }))]
          },
          { type: 'checkbox', name: 'excludeNoActivity', label: 'Exclude No Activity' },
          { type: 'button', label: 'Search' },
        ]}
      />
    )
  }

  if (type === 'awardManagement') {
    return (
      <div style={{ marginTop: '5%' }}>
        <InputGroup
          filter={filter}
          setFilter={setFilter}
          label='      '
          styles={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end', justifyContent: 'center', gap: '25px' }}
          inputs={[
            locationSelect,
            {
              type: 'select',
              name: 'dateRange',
              label: 'Date Range',
              items: [
                { key: 'All', label: 'All' },
                { key: '3', label: 'Last 3 Months' },
                { key: '6', label: 'Last 6 Months' },
                { key: '9', label: 'Last 9 Months' },
                { key: '12', label: 'Last 12 Months' },
                { key: 'Custom', label: 'Custom' }

              ],
            },
            { type: 'customDateRange', condition: 'custom', conditionTarget: 'dateRange' },
            {
              type: 'select',
              name: 'awardType',
              label: 'Award Type',
              items: [
                { key: 'All', label: 'All' },
                ...options?.map((rowObj: any) => {
                  return { key: rowObj._id, label: rowObj.awardName }
                })
              ],
            },
            {
              type: 'autoComplete',
              name: 'careManager',
              label: 'Care Manager',
              styles: { minWidth: 250, maxWidth: '100%' },
              options: [
                { key: '0', label: 'All' },
                ...users.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
              ],
            },
            {
              type: 'autoComplete',
              name: 'carePartner',
              label: 'Care Partner',
              styles: { minWidth: 250, maxWidth: '100%' },
              options: [
                { key: '0', label: 'All' },
                ...carePartners.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
              ],
            },
            {
              type: 'select',
              name: 'groupBy',
              label: 'Group By',
              items: [
                { key: 'None', label: 'None' },
                { key: 'Location', label: 'Location' },
                { key: 'Award Type', label: 'Award Type' },
                { key: 'Month', label: 'Month' },
                { key: 'Care Manager', label: 'Care Manager' },
              ],
            },
            { type: 'button', label: 'Search' },
          ]}
        />
      </div>
    )
  }
  if (type === 'recruitingGrid') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label={label}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap' }}
        inputs={[
          // { type: 'checkbox', name: 'flag', label: 'Flag' },
          locationSelect,
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'Active', label: 'Active' },
              // { key: 'Inactive', label: 'Inactive' },
            ],
          },
          // {
          //   type: 'select',
          //   name: 'stage',
          //   label: 'Stage',
          //   items: [{ key: '', label: 'All' }, ...options.map((s: any) => ({ key: s.stage, label: s.stage }))],
          // },
          // {
          //   type: 'select',
          //   name: 'dateRange',
          //   label: 'Date Range',
          //   items: [
          //     { key: 'All', label: 'All' },
          //     { key: '7', label: 'Last 7 Days' },
          //     { key: '14', label: 'Last 14 Days' },
          //     { key: '30', label: 'Last 30 Days' },
          //     { key: 'Custom', label: 'Custom' },
          //   ],
          // },
          // { type: 'customDateRange', condition: 'custom', conditionTarget: 'dateRange' },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'prospectgGrid') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label={label}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap' }}
        inputs={[
          // { type: 'checkbox', name: 'flag', label: 'Flag' },
          locationSelect,
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'Active', label: 'Active' },
              // { key: 'Inactive', label: 'Inactive' },
            ],
          },
          // {
          //   type: 'select',
          //   name: 'stage',
          //   label: 'Stage',
          //   items: [{ key: '', label: 'All' }, ...options.map((s: any) => ({ key: s.stage, label: s.stage }))],
          // },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'userManagement') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label={label}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap' }}
        inputs={[
          locationSelect,
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'Active', label: 'Active' },
              { key: 'Inactive', label: 'Inactive' },
            ],
          },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'Referral Partners') {
    return (
      <InputGroup
        filter={referralFilter}
        setFilter={updateFilter}
        filterPartners={filterPartners}
        resetPartners={resetPartners}
        label={label}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap' }}
        maxWidth={false}
        inputs={[
          { type: 'checkbox', name: 'flag', label: 'Flag' },
          // {
          //   type: 'textField',
          //   name: 'referralPartner',
          //   label: 'Referral Partner Name',
          //   styles: { minWidth: 250, maxWidth: '100%' },
          // },
          {
            type: 'autoComplete',
            name: 'referralPartner',
            label: 'Referral Partner',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...partners.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
            ],
          },
          {
            type: 'autoComplete',
            name: 'companyName',
            label: 'Company Name',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...companies.map((user: any) => ({ label: user.companyName, id: user._id, data: user })),
            ],
          },
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'All', label: 'All' },
              { key: 'Active', label: 'Active' },
              { key: 'Inactive', label: 'Inactive' },
            ],
          },
          locationSelect,
          {
            type: 'select',
            name: 'groupBy',
            label: 'Group By',
            styles: { minWidth: 250, maxWidth: '100%' },
            items: ['City', 'Referral Potential', 'Visit Frequency', 'Company Type', 'None'].map((item: any) => ({ label: item, key: item }))
          },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'Companies') {
    return (
      <InputGroup
        filter={referralFilter}
        setFilter={updateFilter}
        filterPartners={filterCompanies}
        label={label}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap' }}
        maxWidth={false}
        inputs={[
          { type: 'checkbox', name: 'flag', label: 'Flag' },
          {
            type: 'autoComplete',
            name: 'companyName',
            label: 'Company Name',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...companies.map((user: any) => ({ label: user.companyName, id: user._id, data: user })),
            ],
          },
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'All', label: 'All' },
              { key: 'Active', label: 'Active' },
              { key: 'Inactive', label: 'Inactive' },
            ],
          },
          {
            type: 'select',
            name: 'companyType',
            label: 'Company Type',
            styles: { minWidth: 250 },
            items:
              [{ key: 'All', label: 'All' }, ...companyTypes.map((s: any) => ({ key: s._id, label: s.companyType }))]
          },
          locationSelect,
          {
            type: 'select',
            name: 'groupBy',
            label: 'Group By',
            styles: { minWidth: 250, maxWidth: '100%' },
            items: ['City', "Company Type", 'None'].map((item: any) => ({ label: item, key: item }))
          },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'prospects') {
    return (
      <InputGroup
        filter={prospectFilter}
        setFilter={updateProspectFilter}
        changeMilestone={getMilestoneByStage}
        label={label}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap' }}
        inputs={[
          { type: 'checkbox', name: 'flag', label: 'Flag' },
          {
            type: 'autoComplete',
            name: 'prospect',
            label: 'Prospect',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              // { label: 'All', id: 'All' },
              { label: 'All', id: '' },
              ...prospects.map((prospect: any) => {
                return { label: prospect.fullName, id: prospect._id, data: prospect }
              })
            ]
          },
          locationSelect,
          {
            type: 'select',
            name: 'status',
            label: 'Status',
            items: [
              { key: 'All', label: 'All' },
              { key: 'Open', label: 'Active' },
              { key: 'Closed', label: 'Inactive' },
            ],
          },
          // {
          //   type: 'multiSelect',
          //   name: 'stage',
          //   label: 'Stage',
          //   items:
          //     [{ key: 'All', label: 'All' }, ...stageOptions],
          // },
          {
            type: 'multiSelect',
            name: 'milestone',
            label: 'Milestone',
            items:
              [{ key: 'All', label: 'All' }, ...milestones]
          },
          {
            type: 'select',
            name: 'dateRange',
            label: 'Date Range',
            items: [
              { key: 'All', label: 'All' },
              { key: '7', label: 'Last 7 Days' },
              { key: '14', label: 'Last 14 Days' },
              { key: '30', label: 'Last 30 Days' },
              { key: 'Custom', label: 'Custom' },
            ],
          },
          { type: 'customDateRange', condition: 'custom', conditionTarget: 'dateRange' },
          {
            type: 'select',
            name: 'groupBy',
            label: 'Group By',
            items: [
              { key: 'None', label: 'None' },
              { key: 'Location', label: 'Location' },
              { key: 'Milestone', label: 'Milestone' },
              { key: 'Status', label: 'Status' }
            ],
          },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'marketing-activities') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label={label}
        filterPartners={filterPartners}
        resetPartners={resetPartners}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap' }}
        inputs={[
          locationSelect,
          {
            type: 'select',
            name: 'dateRange',
            label: 'Date Range',
            items: [
              { key: 'All', label: 'All' },
              { key: '7', label: 'Last 7 Days' },
              { key: '14', label: 'Last 14 Days' },
              { key: '30', label: 'Last 30 Days' },
              { key: 'Custom', label: 'Custom' },
            ],
          },
          { type: 'customDateRange', condition: 'custom', conditionTarget: 'dateRange' },
          {
            type: 'select',
            name: 'activity',
            label: 'Activity Type',
            styles: { minWidth: 250, maxWidth: '100%' },
            items: [{ key: 'All', label: 'All' }, ...activityTypes.map((activity: any, idx: any) => {
              return ({ key: activity.type, label: activity.type })
            })]
          },
          {
            type: 'autoComplete',
            name: 'marketingManager',
            label: 'Created By',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...users.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
            ],
          },
          {
            type: 'autoComplete',
            name: 'referralPartner',
            label: 'Referral Partner',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...partners.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
            ],
          },
          {
            type: 'autoComplete',
            name: 'company',
            label: 'Company',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...companies.map((user: any) => ({ label: user.companyName, id: user._id, data: user })),
            ],
          },
          {
            type: 'select',
            name: 'groupBy',
            label: 'Group By',
            styles: { minWidth: 250, maxWidth: '100%' },
            items: options.groupBy.map((activity: any, idx: any) => {
              return ({ key: activity, label: activity })
            })
          },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }
  if (type === 'marketingManagement') {
    return (
      <InputGroup
        filter={filter}
        setFilter={setFilter}
        label={label}
        filterPartners={filterPartners}
        resetPartners={resetPartners}
        styles={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', gap: '25px', flexWrap: 'wrap', width: '100vw !important' }}
        inputs={[
          {
            type: 'autoComplete',
            name: 'marketingManager',
            label: 'Marketing Manager',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...users.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
            ],
          },
          {
            type: 'select',
            name: 'dateRange',
            label: 'Date Range',
            items: [
              { key: 'All', label: 'All' },
              { key: '7', label: 'Next 7 Days' },
              { key: '14', label: 'Next 14 Days' },
              { key: '30', label: 'Next 30 Days' },
              { key: 'Custom', label: 'Custom' },
            ],
          },
          locationSelect,
          { type: 'customDateRange', condition: 'custom', conditionTarget: 'dateRange' },
          {
            type: 'autoComplete',
            name: 'referralPartner',
            label: 'Referral Partner',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...partners.map((user: any) => ({ label: formatName(user.firstName, user.lastName), id: user._id, data: user })),
            ],
          },
          {
            type: 'autoComplete',
            name: 'company',
            label: 'Company',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...companies.map((user: any) => ({ label: user.companyName, id: user._id, data: user })),
            ],
          },
          {
            type: 'autoComplete',
            name: 'prospect',
            label: 'Prospect',
            styles: { minWidth: 250, maxWidth: '100%' },
            options: [
              { key: 'All', label: 'All' },
              ...prospects.map((prospect: any) => ({ label: prospect.fullName, id: prospect._id, data: prospect })),
            ],
          },
          {
            type: 'select',
            name: 'groupBy',
            label: 'Group By',
            items: [
              ...['None', 'Marketing Manager', 'Activity Type', 'Referral Partner', 'Company', 'Prospect', 'Day', 'Week'].map((item: any) => {
                return { key: item, label: item }
              })
            ],
          },
          { type: 'button', label: 'Search' },
        ]}
      />
    );
  }

  return <></>;
};