import { Select as MuiSelect, SelectChangeEvent, FormControl, Box, MenuItem, Checkbox, ListItemText, ListItem } from '@mui/material';
import { ItemType } from 'typings';
import React, { useState } from 'react';
interface SelectProps {
    value: string;
    defaultValue?: string;
    onChange: (e?: SelectChangeEvent) => void;
    name: string;
    items: ItemType[];
    label?: string;
    size?: any;
    styles?: any
}

export function MultiSelect({ value, defaultValue = '', onChange, name, items, label, size = 'small', styles = {} }: SelectProps): JSX.Element {
    const getLabelById = (id: string) => {
        const option = items.find((opt) => opt.key === id);
        return option ? option.label : id; // Return label or id if no match found
    };
    return (
        <Box>
            {label && (<div className="bt">{label}</div>)}
            <FormControl sx={{ minWidth: '250px', maxWidth: '250px' }}>
                {/* <MuiSelect
                    MenuProps={{
                        disableScrollLock: true, PaperProps: {
                            sx: {
                                maxHeight: 200, // Limit dropdown height
                                width: 150, // Adjust dropdown width
                            },
                        },
                    }}
                    value={value}
                    name={name}
                    displayEmpty
                    multiple
                    onChange={onChange}
                    size={size}
                    style={styles ? styles : {}}
                    renderValue={(selected) =>
                        (selected as unknown as string[]).map((id) => getLabelById(id)).join(', ')
                      }
                >
                    {items.flatMap((item: any) => {
                        const isChecked = value ? value.indexOf(item.key) > -1 : false;
                        const mainItem = (
                            <MenuItem key={item.key} value={item.key} divider={item.divider || false}>{item.stage}
                                <ListItem disableGutters dense>
                                    <Checkbox
                                        checked={isChecked}
                                        sx={{ height: 20 }}
                                    />
                                    <ListItemText primary={item.label} />
                                </ListItem>
                            </MenuItem>
                        );
                        return [mainItem]
                  
                    })}

                </MuiSelect> */}
                <MuiSelect
                
                    MenuProps={{
                        disablePortal:true,
                        disableScrollLock: true,
                        PaperProps: {
                            sx: {
                                maxHeight: 200, // Limit dropdown height
                                width: 150, // Adjust dropdown width
                            },
                        },
                    }}
                    value={value}
                    name={name}
                    displayEmpty
                    multiple
                    onChange={onChange}
                    size={size}
                    style={styles ? styles : {}}
                    renderValue={(selected) =>
                        selected==""?"All":
                        (selected as unknown as string[]).map((id) => getLabelById(id)).join(', ')
                    }
                >
                    {items.reduce((acc: JSX.Element[], item: any, index: number, array: any[]) => {
                        const isChecked = value ? value.indexOf(item.key) > -1 : false;
                        const isFirstOfStage = index === 0 || item.stage !== array[index - 1].stage;
                        if (isFirstOfStage) {
                            acc.push(
                                <MenuItem key={`stage-${item.stage}`}  sx={{pointerEvents: 'none'}} divider={item.divider || false}>
                                    {item.stage}
                                </MenuItem>
                            );
                        }
                        acc.push(
                            <MenuItem key={item.key} value={item.key} divider={item.divider || false}>
                                <ListItem disableGutters dense>
                                    <Checkbox
                                        checked={isChecked}
                                        sx={{ height: 20 }}
                                    />
                                    <ListItemText primary={item.label} />
                                </ListItem>
                            </MenuItem>
                        );

                        return acc;
                    }, [])}
                </MuiSelect>
            </FormControl>
        </Box>
    );
}