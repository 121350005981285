import { Container } from '@mui/system';
import { Layout } from 'components/Layout';
import ActivityTabs from 'views/Activity/ActivityTabs';
import { MarketingManagement } from '../MarketingManagement';
import MarketingActivities from '../MarketingActivities/MarketingActivities';
import { Calendar } from '../Calendar';

const TABS = [
  {
    label: 'Completed Activity',
    value: 'marketingDailylog',
    Children: <MarketingActivities></MarketingActivities>,
  },
  {
    label: 'Schedule Activity',
    value: 'marketingManagement',
    Children: <MarketingManagement></MarketingManagement>,
  },
  {
    label: 'Calendar',
    value: 'calendar',
    Children: <Calendar></Calendar>,
  },
];


const Activites = () => {
  return (
    <Layout>
      <div>
        <Container maxWidth={false} sx={{ paddingTop: 15, paddingLeft: '0' }}>
          <ActivityTabs tabs={TABS ||  ['Marketing Management', 'Marketing Daily log', 'Calendar']} HeaderTitle={"Marketing Activities"} ></ActivityTabs>
        </Container>
      </div>
    </Layout>
  );
};

export default Activites;
