import React, { useEffect, useState } from 'react';
import { TableCell, TableRow } from "@mui/material";
import SubRowComponent from './SubRow';

const RowComponent = ({ row, isSubRowVisible, index, delta, calculateTotal }: any) => {
    const [visible, setVisible] = useState<boolean>(isSubRowVisible || false)
    const toggleVisible = () => {
        setVisible(prev => !prev)
    }
    useEffect(() => { setVisible(isSubRowVisible) }, [isSubRowVisible])

    return (
        <>
            <TableRow style={{ "cursor": 'pointer' }} key={index} onClick={() => toggleVisible()}>
                <TableCell align="left" style={{ borderBottom: row?.companyValues?.length && visible ? 'none' : '', }}>{row.label}</TableCell>
                {row?.values?.map((value: any, i: number) => (
                    <TableCell sx={{ padding: 0 }} key={i} align="center" style={{ borderRight: i % 2 === 0 ? '1px dotted grey' : '1px solid grey', borderLeft: i === 0 ? '1px solid grey' : '', height: '20px', borderBottom: !visible ? '' : 'none' }}>
                        {value}
                    </TableCell>
                ))}
                {!delta && calculateTotal(row, index, false)}
            </TableRow>
            {visible && row?.companyValues?.map((subRow: any, subIndex: any) => (
                <SubRowComponent toggleVisible={toggleVisible} key={`sub-${subIndex}`} subRow={subRow} index={subIndex} delta={delta} calculateTotal={calculateTotal} />
            ))}
        </>
    );
};

export default RowComponent;
