import React from 'react';
import { Box } from '@mui/material';
import { Layout } from 'components';
import { ProspectStatusTable } from './ProspectStatusTable';
import { Container } from '@mui/system';

export const ProspectStatus = () => {
  return (
    <Layout>
       <Container maxWidth="xl" sx={{ pt: 0 }}><ProspectStatusTable/></Container>
    </Layout>
  );
};
