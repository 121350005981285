import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Select, MenuItem } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { uploadDocument } from '../Uploader/redux/actions/documentActions'; //../redux/actions/documentActions
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from 'hooks';

const AddFileForm = ({ open, onClose, category }) => {
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [fileName, setFileName] = useState('');
  // const [category, setSelectedValue] = useState('all');
  const [error, setError] = useState('');
  // const [uploadSections, setUploadSections] = useState([]);
  const dispatch = useDispatch();
  const { user } = useAuth();
  const selectedNode = useSelector((state) => state.documents.selectedNode);

  // const { fileSections, fetchDocumentsLoading } = useSelector((state) => state.documents);
  // useEffect(() => {
  //   if (fileSections.length > 0) {
  //     const mappedData = fileSections.map(item => ({
  //       key: item._id,
  //       label: item.type
  //     }));
  //     const d = [{ key: "66d154e6all259a3833fl474", label: 'All' }, ...mappedData]
  //     setUploadSections(d);
  //   }

  // }, [fileSections, fetchDocumentsLoading, dispatch]);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setFile(selectedFile);
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(selectedFile);
    }
  };

  const validateFileName = (name) => {
    if (!name || name.trim() === '') {
      return 'File name is required.';
    }
    if (name.length > 50) {
      return 'File name should not exceed 50 characters.';
    }
    return '';
  };

  const handleSubmit = () => {
    const validationError = validateFileName(fileName);
    if (validationError) {
      setError(validationError);
      return;
    }

    if (file) {
      dispatch(uploadDocument(file, selectedNode, fileName, category, user._id));
      resetForm();
      onClose();
    }
  };

  const resetForm = () => {
    setFile(null);
    setPreviewUrl('');
    setFileName('');
    setError('');
    // setSelectedValue('all');
    const fileInput = document.getElementById('file-upload');
    if (fileInput) fileInput.value = '';
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: {
          maxWidth: '300px',
          overflow: 'hidden'
        },
      }}
    >
      <DialogTitle>Add File</DialogTitle>
      <DialogContent>
        <Box>
          {/* {selectedNode && <Typography variant="body1" sx={{ mt: 2, fontSize: '12px' }}>
            {`ID: ${selectedNode}`}
          </Typography>} */}

          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="file-upload"
          />
          <label htmlFor="file-upload">
            <Button variant="contained" component="span">
              Choose File
            </Button>
          </label>

          <Box display={'flex'} gap={2} alignItems={'center'} pt={2}>
            {previewUrl && (
              <>
                {/* <img src={previewUrl} alt="Image Preview" style={{ maxWidth: '50px', maxHeight: '50px' }} /> */}
                {file && <span style={{ fontSize: '13px' }}>{file.name}</span>}
                <CloseIcon fontSize='16px' style={{ cursor: 'pointer' }} onClick={resetForm} />
              </>
            )}
          </Box>

          {error && (
            <Typography variant="body2" color="error">
              {error}
            </Typography>
          )}
        </Box>
        {/* <Select
          labelId="user-role-select-label"
          id="user-role-select"
          value={category}
          onChange={(e) => setSelectedValue(e.target.value)}
          fullWidth
          margin="dense"
          variant="outlined"
        >
          
          {uploadSections.map(item => (
            <MenuItem key={item.key} value={item.key}>
              {item.label}
            </MenuItem>
          ))}
        </Select> */}
        <TextField
          autoFocus
          margin="dense"
          label="File Name"
          fullWidth
          variant="outlined"
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddFileForm;
