import { createContext, useContext, useState, ReactNode, useEffect } from "react";

const CardFilter = createContext({type: '', setType: (v: any)=>v, setCurrentPage: (v: any) => v});

export function CardFilterProvider({children}: {children: ReactNode}) {
  const [currentPage, setCurrentPage] = useState(null)
  const [type, setType] = useState('All')
  useEffect(() => {
    setType('All')
  }, [currentPage])
  return (
    <CardFilter.Provider value={{type, setType, setCurrentPage}}>
      {children}
    </CardFilter.Provider>
  )
}
export default function useCardFilter() {
  return useContext(CardFilter)
}