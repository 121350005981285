import { Layout } from 'components'
import { useState } from 'react'
import { FilterHeader } from 'views'
import { useAuth } from 'hooks'
import ClientsTotalChart from 'views/Reporting/CareManagment/ClientsTotalChart'
import CarePartnersTotalChart from 'views/Reporting/CareManagment/CarePartnersTotalChart'
import CLientsWonAndLost from 'views/Reporting/CareManagment/ClientsWonAndLost'
import { CareManagementTabs } from 'views/Reporting/CareManagment/CareManagementTabs'

function ProspectsReporting(props: any) {
    const { user } = useAuth();
    const [filter, setFilter] = useState({
        location: { id: user ? user?.location._id : '0', value: user ? user?.location?.location : 'All' },
        timeFrame: { id: 'last6Months', value: 'Last 6 Months' }
    })

    return (
        <Layout>
            <div style={{ marginTop: '10%', marginBottom: '2%' }}>
                <FilterHeader type='prospectsReporting' setFilter={setFilter} filter={filter} />
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridGap: '30px',
                placeItems: 'center',
                paddingLeft: '130px',
                paddingRight: '100px'
            }}>
                <ClientsTotalChart filter={filter} />
                <CLientsWonAndLost filter={filter} client={true} title='Clients' />
                <CarePartnersTotalChart filter={filter} />
                <CLientsWonAndLost filter={filter} client={false} title='Care Partners' />
            </div>
            <div style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gridGap: '20px',
                placeItems: 'center',
                paddingLeft: '130px',
                paddingRight: '100px'
            }}>
                <CareManagementTabs filter={filter} />
            </div>
        </Layout>
    )
}

export default ProspectsReporting